import React from "react";
import {Map, List} from "immutable";
import {marginStyles, paddingStyles, applyStyles, groupStyles} from "../../ModuleStyles";
import {createModuleFromId} from "../../ModuleFactory";

export default function ItemContainer(props) {

  let {width, height, data, modules, moduleId, pageActions} = props;
  let moduleOrder = data.get("moduleOrder");

  let defaultFlex = data.get("itemSize", "1 1 50%");
  let defaultHeight = data.get("itemHeight", "auto");
  let defaultPosition = "relative";

  let elements = moduleOrder.map((col, pos) => {
    let moduleId = Map.isMap(col) ? col.get("id") : col;
    let colStyles = Map.isMap(col) ? {
      position: col.get("position", defaultPosition),
      flex: col.get("size", defaultFlex),
      height: col.get("height", defaultHeight)
    } : { 
      position: defaultPosition,
      flex: defaultFlex, 
      height: defaultHeight 
    }
    return (
      <div key={pos} className="container-col" style={colStyles}>
        {maybeWrap(
          data,
          moduleId, 
          createModuleFromId(moduleId, modules, width, height, pageActions)
        )}
      </div>
    )
  })

  let styles = getStyles(data).toJS();
  let innerStyles = getInnerStyles(data).toJS();
  
  return (
    <div className="ItemContainer" style={styles} id={moduleId}>
      <div className="inner" style={innerStyles}>
        {elements}
      </div>
    </div>
  )
}

function maybeWrap(data, moduleId, module) {
  // Safari currently has a bug where the height of 100% cannot be resolved for a relative object in certain scenarios.
  // This little trick fixes that.
  if (data.get("autoSize", "") !== moduleId) return module;
  let styles = {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%"
  }
  return <div style={styles}>{module}</div>
}


function getInnerStyles(data) {
  let defaultStyles = Map({width: "100%", height: "100%", display: "flex", "flexWrap": "wrap"});
  let additionalStyles = List(["maxWidth", "justifyContent", "alignItems", "flexWrap"]);
  return applyStyles(additionalStyles, data.getIn(["styles", "#inner"]), defaultStyles);
}

function getStyles(data) {
  // TODO: Investigage why display is "inline-block" by default.
  let defaultStyles = Map({position: "relative", width: "100%"});
  let additionalStyles = List(["@backgroundImages", "backgroundColor", "color", "textAlign", "minHeight", "width", "height", "display"]);
  return applyStyles(groupStyles(marginStyles(), paddingStyles(), additionalStyles), data.get("styles"), defaultStyles);
}
