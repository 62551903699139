import React from "react";
import PropertyGalleryBrowser from "./PropertyGalleryBrowser";
import {useSelector} from "react-redux";
import {List} from "immutable";

export default function DynPropertyGalleryBrowser({data}) {
  const width = useSelector((state) => state.getIn(["ui", "width"]));
  const mobileMode = useSelector((state) => state.getIn(["ui", "mobileMode"]));

  return (
    <PropertyGalleryBrowser
      width={width}
      baseUrl={data.get("baseUrl", "")}
      floors={data.get("floors", List())}
      mobile={mobileMode}
      />
  )
}