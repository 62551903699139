import React, {Component} from "react";
import Video from "./Video";
import VideoProgressBar from "./VideoProgressBar";
import VideoPlaybackToggleButton from "./VideoPlaybackToggleButton";
import VideoVolumeButton from "./VideoVolumeButton";
import {cx} from "../../../lib/ClassSet";
import MessageBus from "../../../lib/MessageBus";
import { v1 as uuid } from 'uuid';
import "./VideoPlayer.scss";

class VideoPlayer extends Component {

  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.updateProgressBar = this.updateProgressBar.bind(this);
    this.updateDuration = this.updateDuration.bind(this);
    this.videoEnded = this.videoEnded.bind(this);
    this.videoStarted = this.videoStarted.bind(this);
    this.updateBufferBar = this.updateBufferBar.bind(this);
    this.toggleMute = this.toggleMute.bind(this);
    this.handleSeek = this.handleSeek.bind(this);
    this.handleToggleVideo = this.handleToggleVideo.bind(this);
    this.state = {
      playing: false,
      percentPlayed: 0,
      percentBuffered: 0,
      duration: 0,
      currentTime: 0,
      muted: props.muted,
      volumeLevel: 0.5,
      fullScreen: false,
      publishEvents: true      
    }
  }

  componentDidMount() {
    this.videoId = uuid();
    var self = this;
    this.subscription = MessageBus.subscribe("VideoPlayer.start", "playing", (data, envelope) => {
      if (self.videoId !== data.id && self.state.playing) {
        self.togglePlayback();
      }
    })

    this.subscription2 = MessageBus.subscribe("VideoPlayer.stop", "stopped", (data, envelope) => {
      if (self.videoId !== data.id && !self.state.playing && self.props.autoPlay && self.props.loop) {
        self.togglePlayback();
      }
    })
  }

  componentWillUnmount() {
    if (this.state.playing) this._publishStopEvent();
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();    
  }

  videoEnded() {
    var self = this;
    this.setState({
      percentPlayed: 100,
      playing: false
    }, function() {
      self._publishStopEvent();
      self._signalPlaybackChanged();
      self._signalPlaybackEnded();
    })
  }

  videoStarted() {
    let self = this;
    this.setState({playing: true}, () => {
      self._publishPlayEvent();
      self._signalPlaybackChanged();
    })
  }

  togglePlayback() {
    var self = this;
    this.setState({
      playing: !this.state.playing
    }, function(){
      if (this.state.playing){
        this.videoRef.current.play();
        self._publishPlayEvent();
      }else{
        this.videoRef.current.pause();
        this._publishStopEvent();
      }
      self._signalPlaybackChanged();
    })
  }

  _publishPlayEvent() {
    if (!this.props.publishEvents) return;
    MessageBus.publish("VideoPlayer.start", "playing", {
      id: this.videoId
    })
  }

  _publishStopEvent() {
    if (!this.props.publishEvents) return;
    MessageBus.publish("VideoPlayer.stop", "stopped", {
      id: this.videoId
    })
  }

  _signalPlaybackChanged() {
    if (this.props.onPlaybackChanged) {
      this.props.onPlaybackChanged(this.state.playing);
    }
  }

  _signalPlaybackEnded() {
    if (this.props.onPlaybackEnded) {
      this.props.onPlaybackEnded();
    }
  }

  _signalTimeChange(times) {
    if (this.props.onTimeChange) {
      this.props.onTimeChange(times);
    }
  }

  updateDuration(duration) {
    this.setState({duration: duration});
  }

  updateBufferBar(buffered) {
    this.setState({percentBuffered: buffered});
  }

  updateProgressBar(times) {
    var percentPlayed = Math.floor((100 / times.duration) * times.currentTime);
    let self = this;
    this.setState({
      currentTime: times.currentTime,
      percentPlayed: percentPlayed,
      duration: times.duration
    }, () => {
      self._signalTimeChange(times);
    })
  }

  toggleMute() {
    this.setState({
      muted: !this.state.muted
    }, function(){
      this.videoRef.current.mute(this.state.muted);
    })
  }

  toggleFullscreen() {
    this.setState({
      fullScreen: !this.state.fullScreen
    }, function(){
      if (this.state.fullScreen){
        this.webkitRequestFullScreen();
      }else{
        document.webkitExitFullscreen();
      }
    })
  }

  handleVolumeChange(value) {
    this.setState({volumeLevel: value / 100}, function(){
      this.videoRef.current.volume(this.state.volumeLevel);
    })
  }

  handleSeek(val) {
    this.videoRef.current.seekToPercentage(val);
    if (!this.state.playing) {
      this.togglePlayback();
    }
  }

  seekToPercentage(percentage) {
    this.handleSeek(percentage);
  }

  handleToggleVideo() {
    this.togglePlayback();
  }

  getVideoControls() {
    if (this.props.controls === false) return null;
    return (
      <div className="video_controls">
        <VideoProgressBar 
          percentPlayed={this.state.percentPlayed}
          percentBuffered={this.state.percentBuffered}
          seekHandler={this.handleSeek}
          />
        <VideoPlaybackToggleButton
          onTogglePlayback={this.handleToggleVideo}
          playing={this.state.playing}
          />
        <VideoVolumeButton
          muted={this.state.muted}
          volumeLevel={this.state.volumeLevel}
          toggleVolume={this.toggleMute}
          changeVolume={this.handleVolumeChange}
          />
      </div>
    )
  }

  render() {
    var styles = {
      width: this.props.width,
      height: this.props.height,
      visibility: (this.props.visible ? "visible" : "hidden")
    }

    var classes = cx({
      "VideoPlayer": true,
      "VideoPlayer-fill": this.props.fill,
      "VideoPlayer-fit": this.props.fit
    });

    return (
      <div className={classes} style={styles}>
        <Video 
          ref={this.videoRef}
          autoPlay={this.props.autoPlay}
          muted={this.props.muted}
          loop={this.props.loop}
          fill={this.props.fill}
          url={this.props.options.url}
          volume={this.state.volumeLevel}
          poster={this.props.options.poster}
          currentTimeChanged={this.updateProgressBar}
          durationChanged={this.updateDuration}
          updatePlaybackStatus={this.videoEnded}
          playbackStarted={this.videoStarted}
          bufferChanged={this.updateBufferBar}
          />
          {this.getVideoControls()}          
      </div>
    )
  }
}

VideoPlayer.defaultProps = {
  visible: true,
  autoPlay: false,
  muted: false,
  controls: true,
  loop: false,
  fill: false
}

export default VideoPlayer;