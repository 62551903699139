import React, {Component} from "react";

class Video extends Component {

  constructor(props) {
    super(props);
    this.videoRef = React.createRef();    
  }

  updateCurrentTime(times) {
    this.props.currentTimeChanged(times);
  }

  updateDuration(duration) {
    this.props.durationChanged(duration);
  }
  
  playbackStarted() {
    this.props.playbackStarted();
  }
  
  playbackChanged(shouldPause) {
    this.props.updatePlaybackStatus(shouldPause);
  }

  updateBuffer(buffered) {
    this.props.bufferChanged(buffered);
  }

  componentWillUnmount() {
    clearInterval(this.bufferCheckIntervalId);
  }

  play() {
    this.videoRef.current.play();
  }

  pause() {
    this.videoRef.current.pause();
  }

  mute(muted) {
    this.videoRef.current.muted = muted;
  }

  volume(level) {
    this.videoRef.current.volume = level;
  }

  seekToPercentage(val) {
    this.videoRef.current.currentTime = this.videoRef.current.duration * val;
  }

  componentDidMount() {
    var video = this.videoRef.current;
    var self = this;

    video.addEventListener("playing", (e) => {
      self.playbackStarted();
    });

    video.addEventListener('loadstart', function(e) {
      clearInterval(self.bufferCheckIntervalId);
      self.bufferCheckIntervalId = setInterval(function(){
        if (video.buffered.length === 0) {
          self.updateBuffer(0);
        } else {
          var percent = isNaN(video.duration) ? 0 : (video.buffered.end(0) / video.duration * 100);
          self.updateBuffer(percent);
          if (percent === 100) { clearInterval(self.bufferCheckIntervalId); }
        }
      }, 500);   
    });

    // Sent when playback completes
    video.addEventListener('ended', function(e){
      self.playbackChanged(e.target.ended);
    }, false);

    video.addEventListener('durationchange', function(e){
      self.updateDuration(e.target.duration);
    }, false);

    video.addEventListener('timeupdate', function(e){
      self.updateCurrentTime({
        currentTime: e.target.currentTime,
        duration: e.target.duration
      });
    }, false)
  }

  render() {
    // fill currently requires some CSS
    let extras = {}
    if (!this.props.fill) {
      extras = {
        width: "100%",
        height: "100%"
      }
    }

    return (
      <video
        ref={this.videoRef}
        src={this.props.url}
        poster={this.props.poster}
        autoPlay={this.props.autoPlay}
        loop={this.props.loop}
        muted={this.props.muted}
        {...extras}
        />
    )
  }

}

export default Video;