import React from "react";
import {cx} from "../../../lib/ClassSet";

export default function MenuItem({id, title, selected, onClick}) {
  
  let classNames = cx({
    "item": true,
    "u-selected": selected
  })

  return (
    <div className={classNames} onClick={() => onClick(id)}>
      <div className="icon"><div className="innerIcon"></div></div>
      <div className="title">{title}</div>
    </div>
  )
}