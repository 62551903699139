import React from "react";
import {optimalResURL} from "../../../lib/MediaTools";

const BackgroundImage = (props) => {
  let url = (props.retina) ? optimalResURL(props.url) : props.url;
  let styles = {
    backgroundImage: `url(${url})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    backgroundSize: props.size
  }
  if (props.width) styles.width = props.width;
  if (props.height) styles.height = props.height;

  return (
    <div style={styles} className={`BackgroundImage ${props.className}`}>
      <div className="inner">
        {props.children}
      </div>
    </div>
  )
}

BackgroundImage.defaultProps = {
  className: "",
  retina: true,
  size: "cover"
}

export default BackgroundImage;