import React, {useEffect} from "react";
import DynamicPageLoader from "./pages/DynamicPageLoader";
import PagesUnderlay from "./pages/components/PagesUnderlay";
import NavigationMenu from "./components/NavigationMenu";
import PopupContainer from "./components/PopupContainer";
import Conditional from "./components/Conditional";
import LoginPage from "./auth/LoginPage";
import {useSelector} from "react-redux";
import {useSetWindowSize} from "./hooks/uiHooks";
import {PAGES_PAGE} from "./config";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import './App.scss';

function App() {
  const width = useSelector(state => state.getIn(["ui", "width"]));
  const height = useSelector(state => state.getIn(["ui", "height"]));
  const title = useSelector(state => state.getIn(["ui", "pageHeader", "title"]));
  const authorized = useSelector(state => state.getIn(["auth", "authorized"]));
  const pageStylesPathname = useSelector(state => state.getIn(["ui", "pageStylesPathname"]));
  const pageStyles = useSelector(state => state.getIn(["ui", "pageStyles"]));
  useSetWindowSize();

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title])

  return (
    <Router>
      <div className="App">
        <NavigationMenu />
        <PagesUnderlay width={width} height={height} pageStyles={pageStyles} pageStylesPathname={pageStylesPathname} />
        <Conditional show={authorized === true}>
          <div className="Pages">
            <Switch>
              <Route path="/:slug">
                <SubPage
                  pageId={PAGES_PAGE} 
                  width={width} 
                  height={height} 
                  baseUrl=""
                />                
              </Route>
              <Route path="/">
                <DynamicPageLoader
                  pageId={PAGES_PAGE} 
                  width={width} 
                  height={height} 
                  baseUrl=""
                  slug=""
                  />
              </Route>
            </Switch>
          </div>
        </Conditional>
        <Conditional show={authorized === false}>
          <LoginPage />
        </Conditional>
        <PopupContainer windowWidth={width} windowHeight={height} />
      </div>
    </Router>
  )
}

function SubPage(props) {
  let {slug} = useParams();

  return (
    <DynamicPageLoader
      pageId={props.pageId}
      width={props.width} 
      height={props.height} 
      baseUrl={props.baseUrl}
      slug={slug}
      />
  )
}


export default App;
