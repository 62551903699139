import React, {useEffect, useRef} from "react";
import {createModuleFromId} from "./ModuleFactory";
import {getScrollableElement} from "./PageTools";

export default function DynamicPageRenderer(props) {
  let rootRef = useRef(null);

  useEffect(() => {
    // Scroll
    let scrollElement = getScrollableElement();
    if (props.styles.get("overflow", "normal") === "scroll") {
      scrollElement = rootRef.current;
    }
    scrollElement.scrollTop = 0;
  }, [props.location.pathname, props.styles])

  let elements = props.moduleOrder.map((moduleId) => {
    return createModuleFromId(moduleId, props.modules, props.width, props.height, props.pageActions);
  })

  var styles = props.styles;
  let className = "DynamicPage DynamicPageRenderer";
  if (styles.has("class")) className = `${className} ${this.props.styles.get("class")}`;

  return (
    <div className={className} ref={rootRef} style={styles}>
      {elements}
    </div>
  )
}