import React from "react";
import GalleryTiles from "./GalleryTiles";
import "./GalleryFloorView.scss";

export default function GalleryFloorView({data, width, getImageUrl}) {

  return (
    <div className="GalleryFloorView">
    {data.get("sections").map((section) => (
      <section key={section.get("id")}>
        <div className="title">{section.get("title")}</div>
        <div className="features">{section.get("features").join(", ")}</div>
        <GalleryTiles data={section.get("images")} width={width} getImageUrl={getImageUrl} />
      </section>
    ))}
    </div>
  )
}

