import {useEffect} from "react"
import {useDispatch} from "react-redux";
import {setWindowSize} from "../actions/uiActions";
import innerHeight from "ios-inner-height";

export function useSetWindowSize() {
  const dispatch = useDispatch();

  useEffect(() => {
    function handleResize(e) {
      dispatch(setWindowSize(window.innerWidth, innerHeight()));
    }
    
    if (window) {
      window.addEventListener("resize", handleResize);
    }    

    return () => {
      if (window) {
        window.removeEventListener("resize", handleResize);
      }
    } 
  }, [dispatch]);

}
