export default function Slide(props) {

  let styles = {
    position: "absolute",
    left: `${props.left}px`,
    top: "0px",
    width: `${props.width}px`,
    height: `${props.height}px`
  }

  return (
    <div className="slide" style={styles}>
      {props.children}
    </div>
  )
}