import React from "react";
import DynamicPageLoader from "../../DynamicPageLoader";
import CloseIcon from "../close_icon/CloseIcon";
import "./ArticleWindow.scss";

const MAX_WIDTH = 1400; //1200 + MARGIN_LEFT * 2
const MAX_HEIGHT = 850;
const MARGIN_LEFT = 50;
const MARGIN_TOP = 50;

function getMarginLeft(windowWidth, windowHeight) {
  if (windowWidth <= 736) return 0;
  if (windowWidth < 1000) return MARGIN_LEFT/2;
  return MARGIN_LEFT;
}
function getMarginTop(windowWidth, windowHeight) {
  if (windowWidth <= 736) return 0;
  if (windowWidth < 1000) return MARGIN_TOP/2;
  return MARGIN_TOP;
}

export default function ArticleWindow(props) {

  let windowWidth = props.width;
  let windowHeight = props.height;
  let marginLeft = getMarginLeft(windowWidth, windowHeight);
  let marginTop = getMarginTop(windowWidth, windowHeight);
  let width = Math.min(windowWidth, MAX_WIDTH) - (marginLeft * 2);
  let height = Math.min(windowHeight, MAX_HEIGHT) - (marginTop * 2);
  let left = Math.round((windowWidth - width)/2);
  let top = Math.round((windowHeight - height)/2);
  let newProps = {
    ...props, 
    width: width, 
    height: height, 
    exportStyles: false,
    exportPageHeader: false
  }
  let styles = {
    position: "absolute",
    left: left,
    top: top,
    overflow: "hidden",
    width: width,
    height: height
  }
  let closerStyles = {
    width: windowWidth,
    height: windowHeight
  }

  return (
    <div className="ArticleWindow">
      <div className="closer" onClick={props.onClose} style={closerStyles} />
      <CloseIcon onClick={props.onClose} />
      <div className="inner" style={styles}>
        <DynamicPageLoader {...newProps} />
      </div>
    </div>
  );
}
