export const FETCH_PAGE = "FETCH_PAGE";
export const FETCH_PAGE_FETCHING = "FETCH_PAGE_FETCHING";
export const FETCH_PAGE_SUCCESS = "FETCH_PAGE_SUCCESS";
export const FETCH_PAGE_FAILED = "FETCH_PAGE_FAILED";
export const START_DEV_MODE = "START_DEV_MODE";
export const DEV_MODE_STARTED = "DEV_MODE_STARTED";
export const FETCH_DEV_PAGE_SUCCESS = "FETCH_DEV_PAGE_SUCCESS";
export const EXPORT_PAGE_STYLES = "EXPORT_PAGE_STYLES";
export const SET_PAGE_INFO = "SET_PAGE_INFO";

export function fetchPage(pageId, meta) {
  return {
    type: FETCH_PAGE,
    pageId, meta
  }
}

export function fetchPageFetching(pageId) {
  return {
    type: FETCH_PAGE_FETCHING,
    pageId
  }
}

export function fetchPageSuccess(pageId, entities, result) {
  return {
    type: FETCH_PAGE_SUCCESS,
    pageId, entities, result
  }
}

export function fetchDevPageSuccess(pageId, entities, result) {
  return {
    type: FETCH_DEV_PAGE_SUCCESS,
    pageId, entities, result
  }
}

export function fetchPageFailed(pageId, code, message) {
  return {
    type: FETCH_PAGE_FAILED,
    pageId, code, message
  }
}

export function startDevMode() {
  return {
    type: START_DEV_MODE
  }
}

export function devModeStarted() {
  return {
    type: DEV_MODE_STARTED
  }
}

export function setPageInfo(header) {
  return {
    type: SET_PAGE_INFO,
    header
  }
}

export function exportPageStyles(styles, pathname) {
  return {
    type: EXPORT_PAGE_STYLES,
    styles, pathname
  }
}