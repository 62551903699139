import {useState} from "react";
import {Link} from "react-router-dom";
import {cx} from "../../../lib/ClassSet";
import {Map, List} from "immutable";
import {marginStyles, applyStyles} from "../../ModuleStyles";
import "./ActionButton.scss";

export default function ActionButton(props) {
  const [hover, setHover] = useState(false);

  // Use pageActions.resolveLink to absolutize a relative scoped link.
  let {data, pageActions} = props;
  let classes = addTheme(data, cx({
    "ActionButton": true,
    "ActionButton-link": data.get("kind", "") === "link",
    "size-md": data.get("size", "medium") === "medium",
    "size-lg": data.get("size", "") === "large"
  }));

  let styles = 
    addHoverStyles(
      hover, 
      data, 
      getStyles(data)
    ).toJS();

  let link = data.get("link");
  let target = data.get("target", "_self");
  let el;
  switch(data.get("kind", "link")) {
    case "email":
      el = <a href={`mailto:${link}`} target="_blank" rel="noreferrer">{data.get("label")}</a>
      break;
    case "action":
      el = <a href="#" onClick={(e) => handleAction(e, data, pageActions)} target={target}>{data.get("label")}</a>
      break;
    case "callback":
      el = <a href="#" onClick={(e) => handleCallback(e, props)} target={target}>{data.get("label")}</a>
      break;        
    default: 
      if (data.get("scope", "relative") === "absolute") {
        el = <a href={pageActions.resolveLink(link, data.get("scope"))} target={target}>{data.get("label")}</a>
      } else {
        el = <Link to={pageActions.resolveLink(link, data.get("scope"))} target={target}>{data.get("label")}</Link>
      }
  }

  return (
    <div className={classes} style={styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {el}
    </div>
  );  

}

function addTheme(data, classes) {
  if (!data.has("theme")) return classes;
  return `${classes} ActionButton-${data.get("theme")}`;
}

function getStyles(data) {
  let baseStyles = data.get("styles", Map());
  return applyStyles(
          marginStyles().concat(
            List(["textAlign", "color", "borderColor", "backgroundColor"])
          ), 
          baseStyles
        );
}

function addHoverStyles(hover, data, styles) {
  if (!hover) return styles;
  let hoverStyles = data.getIn(["styles", "@hover"], Map());
  return styles.merge(hoverStyles);
}

function handleAction(e, data, pageActions) {
  e.preventDefault();
  if (pageActions[data.get("link")]) {
    pageActions[data.get("link")](data.get("actionData", Map()));
  }
}

function handleCallback(e, props) {
  e.preventDefault();
  props.onClick();
}
