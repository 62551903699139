import {Map, List, fromJS} from "immutable";
import {FETCH_PAGE_FETCHING, FETCH_PAGE_SUCCESS, FETCH_PAGE_FAILED, FETCH_DEV_PAGE_SUCCESS, START_DEV_MODE} from "../actions/pageActions";
import {solveRandomBg} from "../ModuleStyles";
import {DEFAULT_PAGE_HEADER, enhancePageHeader} from "../PageTools";
import {Meta, META_LOADING, META_LOADED, META_NOT_LOADED, META_ERROR} from "../Meta";
import {createNavItem} from "../LinkTools";

const initialState = fromJS({
  devMode: false,
  devPageId: ""
})

export const reducer = function(state = initialState, action) {
  switch(action.type) {
    case FETCH_PAGE_FETCHING:
      return state.setIn([action.pageId, "meta"], new Meta({status: META_LOADING}));
      
    case FETCH_PAGE_SUCCESS:
      return writePage(state, action.pageId, action.entities);

    case FETCH_PAGE_FAILED:
      return state.setIn([action.pageId, "meta"], new Meta({status: META_ERROR, errorCode: action.code, errorMessage: action.message}));

    case FETCH_DEV_PAGE_SUCCESS:
      return writePage(state, action.pageId, action.entities)
                  .set("devPageId", action.pageId);

    case START_DEV_MODE:
      return state.set("devMode", true);

    default:
      return state;
  }
}

function writePage(state, pageId, entities) {
  var pages = fromJS(entities.pages);
  var modules = processModules(fromJS(entities.modules));
  var info = fromJS(entities.info[pageId]);

  return state.setIn([pageId, "meta"], new Meta({status: META_LOADED}))
              .setIn([pageId, "info"], info)
              .setIn([pageId, "modules"], modules)
              .setIn([pageId, "pages"], pages);  
}

// TODO: This is a duplicate from experienceEventsReducer.
function processModules(modules = Map()) {
  // This will look through all modules, and see if there are any styles with @backgroundImages that are random.
  let bgKey = ["data", "styles", "@backgroundImages"];
  return modules.map((v, k) => {
    if (!v.hasIn(bgKey)) return v;
    return v.setIn(bgKey, solveRandomBg(v.getIn(bgKey)));
  })
}

export function getMeta(state, pageId) {
  return state.getIn([pageId, "meta"], new Meta({status: META_NOT_LOADED}));
}

export function getMetaGlobal(globalState, pageId) {
  return globalState.getIn(["pages", pageId, "meta"], new Meta({status: META_NOT_LOADED}));
}

export function getLandingPage(state, pageId) {
  var landingPage = state.getIn([pageId, "info", "landingPage"]);
  let page = state.getIn([pageId, "pages", landingPage], Map());

  return Map({page: page, modules: state.getIn([pageId, "modules"])});
}

export function getSubPage(state, pageId, slug) {
  var page = state.getIn([pageId, "pages"], List()).find(x => x.get("slug") === slug);
  if (!page) {
    return Map({page: Map(), modules: Map(), error: true});
  }
  return Map({page: page, modules: state.getIn([pageId, "modules"])});  
}

export function getNavigation(state, pageId) {
  // TODO: Duplicate of experienceEventsReducer
  var base = state.get(pageId, Map());
  if (!base.hasIn(["info", "navigation"])) return List();
  var landingPageId = base.getIn(["info", "landingPage"]);
  var navigation = base.getIn(["info", "navigation"]);
  let pagesState = base.get("pages");
  return navigation.map(item => createNavItem(pagesState, item, landingPageId));
}

export function getPageHeader(state, pageId, slug="") {
  let pageEnhanced = enhancePageHeader(DEFAULT_PAGE_HEADER, state.getIn([pageId, "info"]));
  // Enhance from slug or use landing page.
  let subPageInfo = getSlugOrLandingPage(state, pageId, slug);
  let slugEnhanced = enhancePageHeader(pageEnhanced, subPageInfo.get("page"));
  return slugEnhanced;
}

export function getSlugOrLandingPage(state, pageId, slug="") {
  if (slug === "") {
    return getLandingPage(state, pageId);
  }
  return getSubPage(state, pageId, slug);
}