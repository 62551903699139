export default function VideoVolumeButton(props) {

  return (
    <div className="VolumeButton" onClick={props.toggleVolume}>
      {getIcon(props)}
    </div>  
  )

}

function getIcon(props) {
  if (props.volumeLevel <= 0 || props.muted) return getMutedIcon();
  return getLoudIcon();
}

function getMutedIcon() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 16" version="1.1" preserveAspectRatio="xMidYMid">
      <g id="muted-svg-icon" fill="#000" stroke="#000" strokeWidth="1">
        <path d="M2,5 l4,0 l4,-4 l0,14 l-4,-4 l-4,0 Z" stroke="none"/>
        <line x1="13" y1="5" x2="18" y2="11" />
        <line x1="18" y1="5" x2="13" y2="11" />
      </g>
    </svg>
  )
}

function getLoudIcon() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 16" version="1.1" preserveAspectRatio="xMidYMid">
      <g id="loud-svg-icon" fill="#000" stroke="#000" strokeWidth="1">
        <path d="M2,5 l4,0 l4,-4 l0,14 l-4,-4 l-4,0 Z" stroke="none" strokeWidth="0"/>
        <line x1="14" y1="3" x2="14" y2="13" />
        <line x1="18" y1="1" x2="18" y2="15" />
      </g>
    </svg>
  )    
}