import React from "react";
import {cx} from "../../../lib/ClassSet";
import {List} from "immutable";
import {marginStyles, paddingStyles, textStyles, applyStyles, groupStyles} from "../../ModuleStyles";
import FXReveal from "../fx_reveal/FXReveal";
import {fromMarkdown} from "../../HtmlConverter";
import {wrapStringInHTML} from "../../HtmlUtils";
import "./Title.scss";

const Title = (props) => {

  let {data, moduleId} = props;
  var classes = cx({
    "Title": true,
    "Title-sm": data.get("size", "") === "small",
    "Title-md": data.get("size", "medium") === "medium",
    "Title-lg": data.get("size", "") === "large",
    "Title-xl": data.get("size", "") === "xlarge",
  })
  if (data.has("theme")) classes = `${classes} Title-${data.get("theme")}`;
  let styles = applyStyles(
    groupStyles(
      paddingStyles(),
      textStyles(),
      List(["maxWidth", "color"]),
      marginStyles()
    ),
    data.get("styles")
  ).toJS();

  let el;
  if (data.get("markdown", false) === true) {
    let markdown = fromMarkdown(data.get("text"));
    el = (
      <div id={moduleId} className={classes} style={styles} dangerouslySetInnerHTML={wrapStringInHTML(markdown)} />
    )            
  } else {
    el = (
      <div id={moduleId} className={classes} style={styles}>
        {data.get("text")}
      </div>
    )
  }

  if (!data.has("fx")) return el;
  return React.createElement(FXReveal, data.get("fx").toJS(), el);
}

export default Title;