import React from "react";
import {cx} from "../../../lib/ClassSet";
import "./GalleryViewTypes.scss";

export default function GalleryViewTypes({show, onChange, selectedView}) {
  if (!show) return null;
  let floorClasses = cx({
    "item": true,
    "u-selected": selectedView === "floor"
  })

  let overviewClasses = cx({
    "item": true,
    "u-selected": selectedView === "overview"
  })

  return (
    <div className="GalleryViewTypes">
      <div className={overviewClasses} onClick={() => onChange("overview")}><div className="icon icon-overview"></div>All Rooms</div>
      <div className={floorClasses} onClick={() => onChange("floor")}><div className="icon icon-floor"></div>View by floor</div>
    </div>
  )
}