import React from "react";
import {fromJS, List} from "immutable";

function addPeriod(v) {
  if (v.substr(-1,1) === ".") return v;
  return v + ".";
}

function formatErrors(errors) {
  // Make sure we have periods.
  let updated = errors.map((v, k) => addPeriod(v));
  return updated.join(" ");
}

const FieldError = (props) => {

  let errors = [];
  if (props.serverError) errors.push(props.serverError);
  if (props.showClientError && props.clientError) {
    if (List.isList(props.clientError)) {
      errors = errors.concat(props.clientError.toArray());
    } else {
      errors.push(props.clientError)
    }
  }

  if (errors.length === 0) return null;

  let formattedError = formatErrors(fromJS(errors));
  return (
    <div className="fieldError">{formattedError}</div>
  )
}

FieldError.defaultProps = {
  serverError: "",
  clientError: "",
  showClientError: false
}

export default FieldError;