import {reducer as pageReducer} from "./pages/reducers/pageReducer";
import {reducer as formReducer} from "redux-form/immutable";
import uiReducer from "./reducers/uiReducer";
import popupReducer from "./reducers/popupReducer";
import authReducer from "./reducers/authReducer";
import {combineReducers} from 'redux-immutable';

const reducers = {
  form: formReducer,
  pages: pageReducer,
  popups: popupReducer,
  ui: uiReducer,
  auth: authReducer
}

export default combineReducers(reducers);