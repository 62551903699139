import { normalize, schema } from 'normalizr';
// Add config
import {PAGES_ENDPOINT as baseUrl} from "../../config";
import {makeRequest, buildGetPayload} from "../../lib/JSONApi";

const pageModule = new schema.Entity("modules");
const page = new schema.Entity("pages", {
  modules: new schema.Array(pageModule)
});
const info = new schema.Entity("info", {
  pages: new schema.Array(page)
});


export function getPage(pageId, envelope=false) {
  let defaultUrl = `${baseUrl}?page=${pageId}`;
  let url = envelope ? `${defaultUrl}&envelope=1` : defaultUrl;
  return makeRequest(url, buildGetPayload())
    .then(({response, error}) => {
      if (error) return {error};
      if (envelope) return prepareEnvelope(response);
      return {
        response: normalize(response, info),
        error
      }
    })
}

function prepareEnvelope(response) {
  return {
    response: normalize(response, {
      editorial: info
    })
  }
}

export function normalizePage(response) {
  return normalize(response, info);
}

export function handleEditorial(data) {
  return normalize(data, info);
}