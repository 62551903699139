import React from "react";
import {cx} from "../../../lib/ClassSet";
import FloorOverview from "./FloorOverview";
import "./RoomOverview.scss";

export default function RoomOverview({data, onClick, mobile, getImageUrl}) {
  let classes = cx({
    "GalleryRoomOverview": true,
    "u-mobile": mobile
  })
  return (  
    <div className={classes}>
      {data.map((floor) => (
        <FloorOverview
          key={floor.get("id")}
          data={floor.get("sections")}
          title={floor.get("title")}
          floorId={floor.get("id")}
          onClick={onClick}
          getImageUrl={getImageUrl}
          />
      ))}
    </div>
  )
}