import {Link} from "react-router-dom";
import {applyStyles, groupStyles, marginStyles, paddingStyles, textStyles} from "../../ModuleStyles";
import {List, Map} from "immutable";

export default function PageLinks(props) {

  let {currentNavigation, data} = props;
  let styles = applyStyles(
    groupStyles(marginStyles(), paddingStyles(), List(["backgroundColor"])),
    data.get("styles")
  );

  let buttonStyles = applyStyles(
    groupStyles(textStyles(), marginStyles(), List(["border", "borderColor", "background", "color"])),
    data.getIn(["styles", "#button"])
  )

  let navigation = currentNavigation.get("navigation", Map());

  return (
    <div className="PageLinks" style={styles.toJS()}>
      {navigation.map(x => {
        return <Link 
          key={x.get("slug")} 
          to={x.get("url")} 
          className="Button" 
          activeClassName="active" 
          onlyActiveOnIndex={true}
          style={buttonStyles.toJS()}>
            {x.get("title")}
          </Link>
      })}
    </div>
  );
}