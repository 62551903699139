module.exports = {
  
  padNumber: function(val) {
    if (val < 10) {
      return "0"+val;
    }
    return val;
  },

  sentenceCase: function(str) {
    if (str === "") return str;
    return str.substr(0,1).toUpperCase() + str.substr(1);
  },

  linebreaksToHTML: function(str) {
    if (str === null) str = '';
    return str.split("\n").join("<br>");
  },

  b64EncodeUnicode: function(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
    }))
  },

  replaceAt: function(str, index, character) {
    return str.substr(0, index) + character + str.substr(index+character.length);
  },

  formatNumber: function(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  },

  currencyFormat: function(num, currency, decimals) {
    var k = (decimals === undefined) ? 2 : decimals;
    return currency + " " + num.toFixed(k).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  },

  propertyPriceFormat: function(num, currency) {
    var val = parseInt(num, 10);
    return this.currencyFormat(val, currency, 0);
  },

  pluralize: function(num, singular, plural) {
    if (parseInt(num,10) === 1) return singular;
    return plural;
  },

  pluralizeWithNumber: function(num, singular, plural) {
    return num + " " + this.pluralize(num, singular, plural);
  }  
}