import {useRef} from "react";

export default function VideoProgressBar(props) {

  let timeline = useRef(null);
  var playedStyle = {width: props.percentPlayed + '%'}
  var playHeadStyle = {left: props.percentPlayed + '%'}
  var bufferStyle = {width: props.percentBuffered + '%'}

  function handleClick(e) {
    e.preventDefault();
    if (props.seekHandler) {
      props.seekHandler(getNormalizedRampedX(timeline.current, e.pageX));
    }
  }

  return (
    <div className="timeline" ref={timeline} onClick={handleClick}>
      <div className="progress_bar">
        <div className="playback_percent" style={playedStyle} />
        <div className="play_head" style={playHeadStyle} />
        <div className="buffer_percent" style={bufferStyle} />
      </div>
    </div>
  );

}

function getNormalizedRampedX(el, globalX) {
  var viewportOffset = el.getBoundingClientRect();
  var x = globalX - viewportOffset.left;
  var normalized = Math.max(0, Math.min(viewportOffset.width, x));
  return normalized / viewportOffset.width;
}

