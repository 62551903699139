import {React, useState} from "react";
import {useDispatch} from "react-redux";
import AuthForm from "./forms/AuthForm";
import {setAuthorized} from "../actions/authActions";
import {AUTH_LOGO_URL, AUTH_DESCRIPTION, showBgVideo, getBgVideo, getBgImage, showWfBranding, showAuthDisclaimer, authEmail} from "../config";
import {authenticate} from "./authHelper";
import {canPlayBackgroundVideo} from "../lib/FeatureDetection";
import VideoUtils from "../lib/VideoUtils";
import {buildImgBgStyle} from "../pages/ModuleStyles";
import VideoPlayer from "../pages/modules/video_player/VideoPlayer";
import Conditional from "../components/Conditional";
import "./LoginPage.scss";

export default function LoginPage(props) {
  let [errors, setErrors] = useState({})
  let dispatch = useDispatch();

  function handleSubmit(values) {
    if (!values.get("passcode")) return;
    return authenticate(values.get("passcode"))
      .then((response) => {
        if (response.success === true) {
          setErrors({});
          dispatch(setAuthorized(values.get("passcode")));
        } else {
          setErrors({_error: "Access Denied"});
        }
      });
  }

  let backgroundVideo = null;  
  let videoOptions = {}
  let styles = {}
  if (canPlayBackgroundVideo() && showBgVideo()) {
    videoOptions = {
      url: VideoUtils.getComposedVideoUrl(getBgVideo()),
      poster: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"    
    }
    backgroundVideo =
      <VideoPlayer key="video" 
          visible={true} 
          options={videoOptions} 
          width="100%" 
          height="100%"
          autoPlay={true}
          controls={false}
          loop={true} 
          muted={true} 
          fill={true}
          fit={false} />
  } else {
    styles = buildImgBgStyle(getBgImage()).toJS();
  }

  return (
    <div className="LoginPage" style={styles}>
      {backgroundVideo}
      <div className="inner">
        <div className="u-top-spacer" />
        <div className="dialog">
          <div className="header">
            <Conditional show={showWfBranding()}>
              <div className="worldsfinest-logo" />
            </Conditional>
            <img src={AUTH_LOGO_URL} className="auth-logo" alt="Property Logo" />
            <p className="auth-description">{AUTH_DESCRIPTION}</p>
          </div>
          <div className="content">
            <AuthForm 
              errors={errors}
              onSubmit={handleSubmit}
              submitLabel="Anmelden"
              standbyLabel="Bereithalten..."
              placeholder="Passwort eingeben"
              />
          </div>
          <div className="footer">
            <Conditional show={showAuthDisclaimer()}>
              <p>This is a private website owned and operated by WF International AG. By signing in, you acknowledge and accept that we will track how you use the site by storing and accessing cookies on your device. All content on this website is strictly confidential, and must not be shared without our written authorisation.</p>
            </Conditional>
            <p className="auth-access-email">Zugangsanfrage senden: <a href={`mailto:${authEmail()}`}>{authEmail()}</a></p>
          </div>
        </div>
        <div className="u-bottom-spacer" />
      </div>
    </div>
  )

}

