var Velocity;
if (typeof window !== 'undefined') {
  Velocity = require('velocity-animate');
} else {
  // mocked velocity library
  Velocity = function() {
    return Promise().resolve(true);
  };
}

export default Velocity;