import sha256 from 'crypto-js/sha256'
import {PASSCODE} from "../config";
import {fromJS} from "immutable";

export function authenticate(code) {
  let ms = 2000
  if (process.env.NODE_ENV !== 'production') {
    ms = 100;
  }
  return new Promise((resolve, reject) => {
    let values = fromJS(PASSCODE.split(","));
    setTimeout(() => {
      if (values.some((val) => sha256(code).toString() === val)) {
        if (hasExpired(code, new Date())) {
          resolve({success: false, error: false})
        } else {
          resolve({success: true, error: false})
        }
        resolve({success: true, error: false})
      } else {
        resolve({success: false, error: false})
      }
    }, ms)
  });
}

export function hasExpired(code, date) {
  var expCodeLength = 12; // Code length must be 11 for it to be expirable.
  var offset = 4; // Offset from where date info is iavailable.
  if (code.length != expCodeLength) return false;
  let [lastY, lastM] = lastValidDateMonth(code, offset);
  let curM = date.getMonth();
  let curY = date.getFullYear()
  if (lastY < curY) return true;
  if (lastY === curY && lastM < curM) return true;
  return false;
}

export function lastValidDateMonth(code, offset) {
  let str = code.slice(offset, offset + 3);
  let val = String(parseInt(str, 16));
  let year = parseInt("20" + val.slice(0, 2), 10);
  let month = parseInt(val.slice(2), 10);
  if (isNaN(year) || isNaN(month)) return [2999, 11];
  return [year, month-1];
}