const snakeToCamelCase = function(key) {
  // first_name -> firstName, isbn_10 -> isbn10, scores_a_1 -> scoresA1
  return key.replace(/(_+[a-z0-9])/g, function (snip) {
    return snip.toUpperCase().replace("_", "");
  });    
}

const camelToSnakeCase = function(key) {
  // firstName -> first_name, isbn10 -> isbn_10, scoresA1 -> scores_a_1
  return key.replace(/([A-Za-z])([0-9])/g, function (snip, char, digit) {
    return char + "_" + digit;
  }).replace(/([A-Z])/g, function (snip) {
    return "_" + snip.toLowerCase();
  });
}

export function parse(text, reviver) {
  return text.replace(/"([^"]*)"\s*:/g, snakeToCamelCase);
  // return JSON.parse(text2, reviver);
}

export function stringify(value, replacer, space) {
  var str = JSON.stringify(value, replacer, space);
  return str.replace(/"([^"]*)"\s*:/g, camelToSnakeCase);
}