var _ = require('lodash');

const defaultTransformer = {

  getId: (image) => {
    return image.id;    
  },

  getPreloadUrl: (image) => {
    return image.url;
  }

}

var ImagePreloader = function(callbacks, transformer=defaultTransformer) {
  if (callbacks === null) callbacks = {}
  this.callbacks = _.merge({
    onComplete: function(x) {},
    onImageLoaded: function(x) {}
  }, callbacks);
  this.transformer = transformer;
  this.init();
}

ImagePreloader.prototype = {
  // TODO: Make sure we don't add duplicates if we add such a feature.

  init: function() {
    this.reset();
  },

  load: function(images) {
    this.reset();
    this.queue = images.slice(0);
    this._loadNext();
  },

  reset: function() {
    this.status = 'idle';
    this.queue = [];
    this.loadMemory = {};
  },

  pause: function() {
    this.paused = true;
  },
  isPaused: function() {
    return this.status === 'paused';
  },

  resume: function() {
    if (this.status === 'loading_will_pause') {
      this.status = 'loading';
    } else if (this.status === 'paused') {
      this.status = 'resuming';
      this._loadNext();
    }
  },
  isLoading: function() {
    return ['loading', 'loading_will_pause'].includes(this.status);
  },
  
  hasLoadedId: function(id) {
    return _.has(this.loadMemory, String(id));
  },

  getId: function(id) {
    if (!this.hasLoadedId(id)) return null;
    return this.loadMemory[String(id)];
  },

  destroy: function() {
    this.reset();
    this.callbacks = null;
  },
  
  _load: function(item) {
    var self = this;
    var img = new Image();
    img.onload = function() {
      self._handleImageLoaded(item);
    }
    img.src = this.transformer.getPreloadUrl(item);
  },

  _loadNext: function() {
    if (this.isPaused()) return;
    // Unless empty.
    if (this.queue.length === 0) {
      this._handleComplete();
      return;
    }

    this.status = 'loading';
    var item = this.queue.shift();
    this._load(item);
  },

  _handleImageLoaded: function(item) {
    this.loadMemory[this.transformer.getId(item)] = item;
    if (this.isLoading()) {
      this.callbacks.onImageLoaded(item);
    }
    this._loadNext();
  },

  _handleComplete: function() {
    if (this.isLoading()) {
      // Notify.
      // We don't want to notify if we hit reset.
      this.callbacks.onComplete(this);
    }
    this.status = 'idle';
  }

}

module.exports = ImagePreloader;