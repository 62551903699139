import React, {useState} from "react";
import BgImageSlider from "./BgImageSlider";
import {Map, List} from "immutable";
import {applyStyles} from "../../ModuleStyles";
import CircularControls from "../../components/circular_controls/CircularControls";
import "./DynBgImageSlider.scss";

const DEFAULT_STYLES = Map({
  position: "relative",
  width: "100%",
  height: "100%"
})

export default function DynBgImageSlider({data}) {
  let [currentSlide, setCurrentSlide] = useState(0);

  let theme = data.get("theme", "");
  let showControls = theme === "";
  let size = data.get("size", "small");
  let baseStyles = data.get("styles", Map());
  let styles = applyStyles(
    List(["height"]),
    baseStyles, DEFAULT_STYLES
    );

  function renderControls() {
    if (data.get("theme") !== "circular") return null;

    return (
      <CircularControls 
        styles={data.getIn(["styles", "#circular"], Map())}
        total={data.get("images").size}
        current={currentSlide}
        nextSlide={() => setCurrentSlide(currentSlide + 1)}
        prevSlide={() => setCurrentSlide(currentSlide - 1)}
        />
    )
  }

  return (
    <div className="DynBgImageSlider" style={styles.toJS()}>
      <BgImageSlider 
        size={size}
        showControls={showControls}
        images={data.get("images")} 
        width={styles.get("width", "100%")}
        currentSlide={currentSlide}
        nextSlide={() => this.setState({currentSlide: this.state.currentSlide+1})}
        prevSlide={() => this.setState({currentSlide: this.state.currentSlide-1})}
        />
      {renderControls()}
    </div>
  )
}