import React from "react";
import BackgroundImage from "../../components/background_image/BackgroundImage";
import {List, Range} from "immutable";
import Dimensions from "../../../lib/Dimensions";
import "./GalleryTiles.scss";

export default function GalleryTiles({data, width, getImageUrl}) {
  if (data.size === 0) return null;
  // check if we need to start with a single one.
  let startSingle = (data.size % 2 === 1);
  let result = List();
  if (startSingle) {
    let height = Dimensions.fromString(data.getIn([0, "dims"])).transformW(width).h;
    result = result.push(<BackgroundImage key="first" url={getImageUrl(data.getIn([0, "main"]))} width="100%" height={`${height}px`} />)
    result = doIt(result, splitIntoChunks(data, 2, 1), width, getImageUrl);
  } else {
    result = doIt(result, splitIntoChunks(data, 2), width, getImageUrl)
  }

  return (
    <div className="GalleryTiles" style={{width: `${width}px`}}>
      {result}
    </div>
  )

  // return (
  //   <div className="GalleryTiles" style={{width: `${width}px`}}>
  //     <div className="double double-even" style={{width: "100%"}}>
  //       <BackgroundImage url={images[0].main} height="300px" />
  //       <BackgroundImage url={images[0].main} height="300px" />
  //     </div>
  //     <div className="double double-right" style={{width: "100%"}}>
  //       <BackgroundImage url={images[0].main} height="300px" />
  //       <BackgroundImage url={images[0].main} height="300px" />
  //     </div>
  //     <div className="double double-left" style={{width: "100%"}}>
  //       <BackgroundImage url={images[0].main} height="300px" />
  //       <BackgroundImage url={images[0].main} height="300px" />
  //     </div>
  //   </div>
  // )

}

function doIt(result, chunks, width, getImageUrl) {
  return chunks.reduce((acc, chunk) => {
    let left = Dimensions.fromString(chunk.getIn([0, "dims"]));
    let right = Dimensions.fromString(chunk.getIn([1, "dims"]));
    let leftW = left.transformW(width * 0.5).w;
    let rightW = right.transformW(width * 0.5).w;
    let height = left.transformW(width * 0.5).h;

    if (left.isPortrait() && !right.isPortrait()) {
      height = left.transformW(width * 0.33).h;
      leftW = left.transformW(width * 0.33).w;
      rightW = right.transformW(width * 0.66).w;
    } else if (right.isPortrait() && !left.isPortrait()) {
      height = right.transformW(width * 0.33).h;
      leftW = left.transformW(width * 0.66).w;
      rightW = right.transformW(width * 0.33).w;
    }
    // need to figure out a height;
    return acc.push(
      <div key={`c-${chunk.getIn([0, "main"])}`} className="double" style={{width: "100%"}}>
        <BackgroundImage key={chunk.getIn([0, "main"])} url={getImageUrl(chunk.getIn([0, "main"]))} width={`${leftW}px`} height={`${height}px`} />
        <BackgroundImage key={chunk.getIn([1, "main"])} url={getImageUrl(chunk.getIn([1, "main"]))} width={`${rightW}px`} height={`${height}px`} />
      </div>
    )
  }, result);
}

function splitIntoChunks(list, chunkSize = 1, start=0) {
  return Range(start, list.count(), chunkSize)
    .map(chunkStart => list.slice(chunkStart, chunkStart + chunkSize));
}