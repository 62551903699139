import {fromJS} from "immutable";

export const PAGES_ENDPOINT = process.env.REACT_APP_PAGES_ENDPOINT;
export const PAGES_PAGE = process.env.REACT_APP_PAGES_PAGE;
export const PASSCODE = process.env.REACT_APP_AUTH_PASSCODE;
export const AUTH_LOGO_URL = process.env.REACT_APP_AUTH_LOGO_URL;
export const AUTH_DESCRIPTION = process.env.REACT_APP_AUTH_DESCRIPTION;
export const AUTH_ENABLED = process.env.REACT_APP_AUTH_ENABLED;

export function authEnabled() {
  return process.env.REACT_APP_AUTH_ENABLED !== "NO";
}

export function showBgVideo() {
  return process.env.REACT_APP_AUTH_USE_BG_VIDEO === "YES";
}

export function showAuthDisclaimer() {
  return process.env.REACT_APP_AUTH_SHOW_DISCLAIMER !== "NO";
}

export function showWfBranding() {
  return process.env.REACT_APP_WF_BRANDING !== "NO";
}

export function authEmail() {
  return process.env.REACT_APP_AUTH_ACCESS_EMAIL;
}

export function getBgVideo() {
  return fromJS({
    "url": process.env.REACT_APP_AUTH_BG_VIDEO_URL,
    "overlay": "grid",
    "formats": process.env.REACT_APP_AUTH_BG_VIDEO_FORMATS.split(","),
    "dims": process.env.REACT_APP_AUTH_BG_VIDEO_DIMS  
  });
}

export function getBgImage() {
  return fromJS({
    "url": process.env.REACT_APP_AUTH_BG_IMAGE_URL,
    "retina": process.env.REACT_APP_AUTH_BG_IMAGE_RETINA === "YES",
    "position": process.env.REACT_APP_AUTH_BG_IMAGE_POSITION || "50% 50%"
  })
}