import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from "react-redux";
import {fromJS} from "immutable";
import configureStore from "./configureStore";
import {setWindowSize} from "./actions/uiActions";
import innerHeight from "ios-inner-height";
import './index.scss';
// import reportWebVitals from './reportWebVitals';

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = fromJS(window.__PRELOADED_STATE__);
// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

const store = configureStore(preloadedState);
store.dispatch(setWindowSize(window.innerWidth, innerHeight()));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
