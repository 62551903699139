import React from "react";
import {Map, List} from "immutable";
import {marginStyles, paddingStyles, applyStyles, groupStyles} from "../../ModuleStyles";
import {createModuleFromId} from "../../ModuleFactory";

export default function ColumnContainer(props) {

  let {width, height, data, modules, moduleId, pageActions} = props;
  let columnOrder = data.get("columnOrder");

  let elements = columnOrder.map((col, pos) => {
    let colStyles = { width: col.get("width", "100%"), height: col.get("height", "100%"), display: col.get("display", "flex") };
    if (col.has("direction")) colStyles.flexDirection = col.get("direction");
    if (col.has("wrap")) colStyles.flexWrap = col.get("wrap");
    if (col.has("align")) colStyles.alignItems = fixFlexAlign(col.get("align", "center"));
    if (col.get("width", "").indexOf("px") > 0) colStyles.flexShrink = 0;
    return (
      <div key={pos} className="container-col" style={colStyles}>
        {createMods(col.get("moduleOrder"), modules, width, height, pageActions)}
      </div>
    )
  })

  var styles = getStyles(data).toJS();
  var innerStyles = getInnerStyles(data).toJS();
  
  return (
    <div className="ColumnContainer" style={styles} id={moduleId}>
      <div className="inner" style={innerStyles}>
        {elements}
      </div>
    </div>
  )

}

function createMods(moduleOrder, modules, width, height, pageActions) {
  return moduleOrder.map((moduleId) => {
    return createModuleFromId(moduleId, modules, width, height, pageActions)
  })
}

function fixFlexAlign(val) {
  let newVal = "";
  switch(val) {
    case "bottom": 
      newVal = "flex-end";
      break;
    case "center":
      newVal = "center";
      break;
    case "top":
      newVal = "flex-start";
      break;
    default: 
      newVal = "";
  }
  return newVal;
}

function getInnerStyles(data) {
  let defaultStyles = Map({width: "100%", height: "100%", display: "flex", "flexWrap": "wrap"});
  let additionalStyles = List(["maxWidth", "justifyContent", "alignItems", "flexWrap"]);
  return applyStyles(additionalStyles, data.getIn(["styles", "#inner"]), defaultStyles);
}

function getStyles(data) {
  let defaultStyles = Map({position: "relative", width: "100%", display: "block"});
  let additionalStyles = List(["@backgroundImages", "backgroundColor", "color", "textAlign", "minHeight", "width", "height", "display"]);
  return applyStyles(groupStyles(marginStyles(), paddingStyles(), additionalStyles), data.get("styles"), defaultStyles);
}