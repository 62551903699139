import React from "react";
import {cx} from "../../../lib/ClassSet";
import {List} from "immutable";
import {marginStyles, paddingStyles, textStyles, applyStyles, groupStyles} from "../../ModuleStyles";
import FXReveal from "../fx_reveal/FXReveal";
import {fromMarkdown} from "../../HtmlConverter";
import {wrapStringInHTML} from "../../HtmlUtils";
import "./Paragraph.scss";

export default function Paragraph(props) {

  let {data, moduleId} = props;
  var classes = cx({
    "Paragraph": true,
    "Paragraph--lead": data.get("lead", false) || data.get("size", "") === "large",
    "Paragraph-sm": data.get("size", "") === "small",
  })
  if (data.has("theme")) classes = `${classes} Paragraph-${data.get("theme")}`;
  
  let outerStyles = 
    // Special case when sometimes we need to have a width set, for example in 50% 50% boxes.
    applyStyles(
      groupStyles(
        List(["width", "maxWidth"]),
        marginStyles()
      ),
      data.get("styles")
    ).toJS();

  let styles = 
    applyStyles(
      groupStyles(
        paddingStyles(), 
        textStyles(),
        List(["marginBottom", "marginTop", "color"])
      ), 
      data.get("styles")
    ).toJS();

  let text = data.get("text").slice(data.get("dropcaps", 0));
  let dropcaps = getDropcaps(data);

  let el;
  if (data.get("markdown", false) === true) {
    let markdown = fromMarkdown(text);
    el = (
      <div className={classes} style={outerStyles} id={moduleId}>
        <div style={styles} dangerouslySetInnerHTML={wrapStringInHTML(markdown)}></div>
      </div>
    )            
  } else {
    el = (
      <div className={classes} style={outerStyles} id={moduleId}>
        <p style={styles}>{dropcaps}{text}</p>
      </div>
    )      
  }

  if (!data.has("fx")) return el;
  return React.createElement(FXReveal, data.get("fx").toJS(), el);  
}

function getDropcaps(data) {
  if (!data.has("dropcaps")) return null;
  // TODO: ensure we are not interferring with Markdown when we do dropcaps.
  let text = data.get("text").slice(0,data.get("dropcaps"));
  let styles = applyStyles(List(["color"]), data.getIn(["styles", "#dropcaps"])).toJS();

  return (
    <span key="dropcaps" className="dropcaps" style={styles}>{text}</span>
  );
}
