import {fromJS} from "immutable";
import {authEnabled} from "../config.js";
import { 
  SET_AUTHORIZED
} from "../actions/authActions";

const initialState = fromJS({
  authorized: !authEnabled()
});

const reducer = function(state = initialState, action) {
  switch(action.type) {

    case SET_AUTHORIZED:
      return state.set("authorized", true);

    default:
      return state;
  }
}


export default reducer;