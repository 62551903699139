import {Map} from "immutable";
import MessageBus from "../lib/MessageBus";

// This is helper to dispatch to the message bus.
export function gotoUrl(url) {
  MessageBus.publish("Navigation", "goto", {
    url: url
  })
}

export function extractListingIdFromUrl(url, nonMatchValue="") {  
  let result = url.match(/\/(?:[a-f0-9]{16}|listings)\/([a-z]{2}-[0-9]+)/);
  if (result === null) return nonMatchValue;
  if (result.length === 2) return result[1];
  console.info("No listing id found in url hash");
  return nonMatchValue;
}

export function addUrlToNavigation(nav, baseUrl) {
  return nav.map((v,k) => {
    if (v.get("landing")) return v.set("url", baseUrl);
    return addUrlToSubnavigation(v, baseUrl);
  })
}

function addUrlToSubnavigation(item, baseUrl) {
  if (!item.has("subnav")) return item.set("url", `${baseUrl}/${item.get("slug")}`);
  return item.set("url", `${baseUrl}/${item.get("slug")}`)
             .set("subnav", addUrlToNavigation(item.get("subnav"), baseUrl));
}

export function createNavItem(baseState, idOrMap, landingPageId) {
  if (Map.isMap(idOrMap)) {
    return createNavItemFromMap(baseState, idOrMap, landingPageId);
  }
  return createNavItemFromId(baseState, idOrMap, landingPageId);  
}

function createNavItemFromId(baseState, id, landingPageId) {
  return Map({ 
    landing: id === landingPageId,
    slug: baseState.getIn([id, "slug"]),
    title: baseState.getIn([id, "menuTitle"])
  })  
}

function createNavItemFromMap(baseState, item, landingPageId) {
  if (!item.has("subnav")) {
    return createNavItemFromId(baseState, item.get("id"), landingPageId);
  }

  return Map({
    landing: false,
    slug: item.get("id"),
    title: item.get("title"),
    subnav: item.get("subnav").map(id => createNavItemFromId(baseState, id, landingPageId))
  });
}
