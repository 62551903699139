import React, {useRef, useState} from "react";
import {optimalResURL} from "../../../lib/MediaTools";
import {cx} from "../../../lib/ClassSet";
import Slide from "./Slide";
import "./BgImageSlider.scss";

function getUrlDefault(image) {
  // If we force not to use retina, then just use the default src.
  if (image.get("retina", false) === false) return image.get("url");
  return optimalResURL(image.get("url"));
}

function addCustomClassName(classes, customName) {
  if (customName) return `${classes} ${customName}`;
  return classes;
}

// touchSlideThreshold: How many pixels until it executes the action.
export default function BgImageSlider({className="", images, currentSlide, touchSlideThreshold = 100, size = "small", showControls = true, getUrl = getUrlDefault, width = "100%", height = "100%", nextSlide, prevSlide}) {
  let [touching, setTouching] = useState(false);
  let [touchWidth, setTouchWidth] = useState(0);
  let [touchOffset, setTouchOffset] = useState(0);
  let [posX, setPosX] = useState(0);

  let ref = useRef(null);  

  let styles = {
    position: "absolute",
    top: 0,
    width: width,
    height: height
  }

  let innerStyles = {
    position: "absolute",
    left: touching ? `-${(currentSlide*100)-touchOffset}%` : `-${currentSlide*100}%`,
    top: 0,
    width: "100%",
    height: "100%"      
  }

  let classes = addCustomClassName(cx({
    "BgImageSlider": true,
    "BgImageSlider--noTouch": !touching,
    "BgImageSlider-large": size === "large",
    "BgImageSlider--noControls": !showControls
  }), className);


  function handleTouchStart(e) {
    setTouching(true);
    setTouchOffset(0);
    setTouchWidth(ref.current.getBoundingClientRect().width)
    setPosX(e.touches[0].clientX)
  }

  function handleTouchEnd(e) {
    setTouching(false);
  }

  function handleTouchMove(e) {
    if (!touching) {
      e.preventDefault();
      return;
    }

    let diff = e.touches[0].clientX - posX;
    if (Math.abs(diff) >= touchSlideThreshold) {
      // Qualifies for slide change.
      if (diff < 0 && hasNext()) {
        setTouching(false);
        nextSlide();
      } else if(diff > 0 && hasPrev()) {
        setTouching(false);
        prevSlide();
      }
    } else {
      // Move it to indicate current status.
      // What's the percentage of the move?
      let percentageMove = Math.round((Math.abs(diff) / touchWidth)*100);
      let newTouchOffset = (diff < 0) ? -percentageMove : percentageMove;
      // touchOffset is a percentage between 0-100.
      setTouchOffset(newTouchOffset);
    }
  }

  function handleTouchCancel(e) {
    setTouching(false);
  }

  function renderPrevControl() {
    let classes = cx({
      "prevControl": true,
      "control": true,
      "disabled": !hasPrev()
    })
    return (
      <div key="prevControl" className={classes} onClick={handlePrevSlide}>
        <div className="icon"/>
      </div>
    )
  }

  function renderNextControl() {
    let classes = cx({
      "nextControl": true,
      "control": true,
      "disabled": !hasNext()
    })
    return (
      <div key="nextControl" className={classes} onClick={handleNextSlide}>
        <div className="icon"/>
      </div>
    )
  }

  function hasPrev() {
    return currentSlide > 0;
  }

  function hasNext() {
    return currentSlide < (images.size-1);
  }

  function handlePrevSlide(e) {
    e.preventDefault();
    if (hasPrev()) {
      prevSlide();
    }
  }

  function handleNextSlide(e) {
    e.preventDefault();
    if (hasNext()) {
      nextSlide();
    }
  }

  let slides = images.map((v,k) => {
    return (
      <Slide key={k} offset={k} image={v} current={currentSlide} getUrl={getUrl} />
    )
  })

  return (
    <div className={classes} ref={ref} style={styles}>
      <div className="controls" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onTouchMove={handleTouchMove} onTouchCancel={handleTouchCancel}>
        {renderPrevControl()}
        {renderNextControl()}
      </div>
      <div className="inner" style={innerStyles}>
        {slides}
      </div>
    </div>
  )
}
