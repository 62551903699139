import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSlugOrLandingPage, getPageHeader, getNavigation} from "./reducers/pageReducer";
import {transformData} from "./ModuleDataTransformer";
import DynamicPageRenderer from "./DynamicPageRenderer";
import PageNotFound from "./components/PageNotFound";
import {setPageInfo, exportPageStyles} from "./actions/pageActions";
import {setNavigation, showArticlePopup, showVideoPlayer} from "../actions/uiActions";
import {addUrlToNavigation} from "./LinkTools";
import usePrevious from "../hooks/usePrevious";
import {Map} from "immutable";
import {withRouter} from "react-router-dom";

const DynamicPage = (props) => {
  const dispatch = useDispatch()
  const pageData = useSelector(state => getSlugOrLandingPage(state.get("pages"), props.pageId, props.slug));
  const pageHeader = useSelector(state => getPageHeader(state.get("pages"), props.pageId, props.slug));
  const navigation = useSelector(state => getNavigation(state.get("pages"), props.pageId));
  const oldPageHeader = usePrevious(pageHeader);
  const oldNavigation = usePrevious(navigation);

  let {baseUrl} = props;
  let page = transformData(pageData.get("page"), props.width, props.height);
  let styles = page.get("styles", Map());

  useEffect(() => {
    if (props.exportPageHeader && !pageHeader.equals(oldPageHeader)) {
      dispatch(setPageInfo(pageHeader));
    }
  }, [dispatch, styles, props.exportPageHeader, oldPageHeader, pageHeader])


  useEffect(() => {
    if (props.exportStyles) {
      dispatch(exportPageStyles(styles, props.location.pathname));
    }
  }, [dispatch, styles, props.exportStyles, props.location.pathname])


  // Navigation
  useEffect(() => {
    if (!navigation.equals(oldNavigation)) {
      dispatch(setNavigation(
        {},
        addUrlToNavigation(navigation, baseUrl).toJS(), 
        // props.listing.get("title"),
        "",
        baseUrl
      ));    
    }
  }, [props.location, navigation, oldNavigation, baseUrl, dispatch])
  
  // TODO: We are creating new functions on each render. Should remove from here.
  let pageActions = {
    resolveLink: function(link, scope) {
      if (link.indexOf("http") === 0) return link;
      if (link === "absolute") return link;
      // Let's look for the link in our pages.
      let navItem = navigation.find(x => x.get("slug") === link);
      // If this is a landing page, then we choose not to add the slug.
      if (navItem && navItem.get("landing")) return baseUrl;
      return `${baseUrl}/${link}`;
    },
    showArticlePopup: function(pageId, slug) {
      dispatch(showArticlePopup(pageId, slug));
    },
    showVideoPlayer: function(url, formats, dims) {
      dispatch(showVideoPlayer(url, formats, dims));
    }
  }

  // TODO: Optimize re-rendering by lifting out some exports that are causing constant updates.
  if (pageData.get("error") === true) {
    return <PageNotFound height={props.height} />
  }


  return (
    <DynamicPageRenderer 
      modules={pageData.get("modules")}
      moduleOrder={page.get("moduleOrder")}
      pageActions={pageActions}
      styles={styles}
      width={props.width}
      height={props.height}
      location={props.location}
      />
  )
}

DynamicPage.defaultProps = {
  scrollable: true,
  exportStyles: true,
  exportPageHeader: true  
}

export default withRouter(DynamicPage);