import {Map} from "immutable";

export function isRetina() {
  return (window.devicePixelRatio > 1);
}

export function retinaURL(url) {
  return url.replace(/(\.[a-z]{3})$/, "@2x$1");
}

export function optimalResURL(url) {
  if (isRetina(url)) return retinaURL(url);
  return url;
}

export function optimalResURLFromMap(data = Map()) {
  if (data.get("retina", true) === false) return data.get("url");
  return optimalResURL(data.get("url"));  
}
