import {Map, List} from "immutable";

const VideoUtils = {

  hasPlayableFormat(formats = List()) {
    return this.getPlayableFormat(formats) !== "";
  },

  getComposedVideoUrl(info = Map()) {
    var format = this.getPlayableFormat(info.get("formats"));
    if (format === "") return "";
    return info.get("url").concat(info.get("dims")).concat(".").concat(format);    
  },

  getPlayableFormat(formats = List()) {
    // Wrapped in try...catch as document might not always be available.
    try {
      var testEl = document.createElement( "video" );
      if ( testEl.canPlayType ) {
        // Check for h264 support
        var h264 = "" !== ( testEl.canPlayType( 'video/mp4; codecs="avc1.42E01E"' )
            || testEl.canPlayType( 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"' ) );

        // Check for Webm support
        var webm = "" !== testEl.canPlayType( 'video/webm; codecs="vp9, opus"' );

        if (h264 && formats.includes("mp4")) return "mp4";
        if (webm && formats.includes("webm")) return "webm";
      }
    } catch(e) {
      return "";    
    }
  }

}

export default VideoUtils;