import {List, Map} from "immutable";
import {optimalResURL} from "../lib/MediaTools";
import {getRandomInt} from "../lib/Random";

export function groupStyles(...group) {
  return List(group).reduce((reduction, member) => {
    return reduction.concat(member);
  }, List())
}

export function dimStyles(dir = List()) {
  return dir.merge(List(["width", "height"]));
}

export function paddingStyles(dir = List()) {
  return dir.merge(List(["padding", "paddingLeft", "paddingRight", "paddingTop", "paddingBottom"]));
}

export function marginStyles(dir = List()) {
  return dir.merge(List(["margin", "marginLeft", "marginRight", "marginTop", "marginBottom"]));
}

export function positionStyles(dir = List()) {
  return dir.merge(List(["position", "top", "left", "right", "bottom"]));
}

export function textStyles(dir = List()) {
  return dir.merge(List(["letterSpacing", "lineHeight", "textTransform", "textAlign", "fontSize", "lineHeight"]));
}

export function unsimplifyFlexRule(val) {
  if (val === "bottom") return "flex-end";
  if (val === "top") return "flex-start";
  return val;
}

function simplifySizes(padding, key="padding") {
  let entities = padding.split(" ");
  let legend = [];
  switch(entities.length) {
    case 2: // vertical | horizontal
      legend = [0,1,0,1];
      break;
    case 3: // top | horizontal | bottom
      legend = [0,1,2,1];
      break;
    case 4: // top | right | bottom | left
      legend = [0,1,2,3];
      break;
    default:
      legend = [0,0,0,0];
      break;
  }
  return Map()
          .set(`${key}Top`, addMissingMeasurement(entities[legend[0]]))
          .set(`${key}Right`, addMissingMeasurement(entities[legend[1]]))
          .set(`${key}Bottom`, addMissingMeasurement(entities[legend[2]]))
          .set(`${key}Left`, addMissingMeasurement(entities[legend[3]]));
}

function addMissingMeasurement(val, measurement = "px") {
  let result = val.match(/^([0-9]+)$/);
  if (result) return `${result[1]}${measurement}`;
  return val;
}

export function applyStyles(dir = List(), styles = Map(), initial = Map()) {
  if (dir.size === 0) return initial;
  return dir.reduce(function(tmpResult, p, i) {
     if (!styles.has(p)) return tmpResult;
     switch(p) {
      case "padding":
        return tmpResult.merge(simplifySizes(styles.get(p), p));
      case "margin":
        return tmpResult.merge(simplifySizes(styles.get(p), p));
      case "maxWidth":
        return tmpResult.merge({maxWidth: styles.get(p), marginLeft: "auto", marginRight: "auto"});
      case "boxShadow":
        return tmpResult.merge({
          "WebkitBoxShadow": styles.get(p),
          "MozBoxShadow": styles.get(p),
          "boxShadow": styles.get(p)     
        });
      case "@flex":
        if (styles.get("@flex") === "unset") return tmpResult;
        return tmpResult.set("height", "100%")
                  .set("display", "flex")
                  .set("flexDirection", "column")
                  .set("alignItems", "center")
                  .set("justifyContent", unsimplifyFlexRule(styles.get("@flex")));
      case "@flexWide":
        if (styles.get("@flexWide") === "unset") return tmpResult;
        return tmpResult.set("width", "100%")
                  .set("display", "flex")
                  .set("flexDirection", "row")
                  .set("alignItems", styles.get("@flexWide"));
      case "alignItems":
        if (styles.get("alignItems") === "unset") return tmpResult;
        return tmpResult.set("alignItems", unsimplifyFlexRule(styles.get("alignItems")));
      case "@backgroundImages":
        if (styles.get("@backgroundImages") === "unset") return tmpResult;
        var imgs = styles.get("@backgroundImages");
        return tmpResult.merge(buildImgBgStyles(imgs));

      default:
        return tmpResult.set(p, styles.get(p));
     }
  }, initial);    
}

export function solveRandomBg(imgs) {
  // Unfortunately this does not work due to the fact that Modulestyles is called on every render,
  // hence we would get a new image each time we would do something like resizing screen.
  // This will group all {random: true} together and insert one of them at first seen position.
  // Find first index.
  let index = imgs.findIndex(x => x.get("random") === true);
  if (index < 0) return imgs;
  // Get all randoms.
  let randoms = imgs.filter(x => x.get("random"));  
  // Get all non-randoms.
  let normals = imgs.filter(x => x.get("random", false) === false);
  // Set a random index.
  let randomIndex = getRandomInt(randoms.size);
  //   Insert at position.
  let solved = normals.splice(index, 0, randoms.get(randomIndex));
    
  return solved;
}

export function buildImgBgStyles(imgs) {
  let collected = imgs.reduce((reduction, img) => {
    if (img.size === 0) return reduction;
    return reduction.push(buildImgBgStyle(img));
  }, List());

  return ["backgroundImage", "backgroundSize", "backgroundPosition", "backgroundRepeat", "backgroundAttachment"].reduce((reduction, style) => {
    return reduction.set(style, collected.map(v => v.get(style)).join(","));
  }, Map());
}

export function buildImgBgStyle(img) {
  return [setBgImageUrl, setBgImageSize, setBgImagePosition, setBgImageRepeat, setBgImageAttachment].reduce((reduction, value) => {
    return value(reduction, img);
  }, Map());
}


export function applyAbsoluteMeasurements(styles=Map(), absWidth, absHeight) {
  // Forces the height to the height passed on via props.
  return List(["height", "minHeight", "maxHeight"]).reduce((reduction, val) => {
    if (styles.has(val)) {
      return reduction.set(val, getAbsoluteFromString(styles.get(val), absHeight)); 
    }
    return reduction;
  }, styles);
}

function getAbsoluteFromString(str, measure) {
  let safeStr = String(str);
  if (safeStr.substr(0,8) !== "absolute") return str;
  let scalar = parseInt(safeStr.substring(9), 10) / 100;
  return Math.round(scalar * measure);
}

function setBgImageUrl(css, img) {
  if (img.has("gradient")) return setBgImageGradient(css, img);
  let url = img.get("url");
  if (img.get("retina", false)) {
    url = optimalResURL(url);
  }
  return css.set("backgroundImage", `url(${url})`);
}

function setBgImageGradient(css, img) {
  return css.set("backgroundImage", img.get("gradient"));  
}

function setBgImageSize(css, img) {
  return css.set("backgroundSize", img.get("size", "cover"))
}

function setBgImagePosition(css, img) {
  return css.set("backgroundPosition", img.get("position", "50% 50%"));
}

function setBgImageRepeat(css, img) {
  return css.set("backgroundRepeat", img.get("repeat", "no-repeat"));
}

function setBgImageAttachment(css, img) {
  return css.set("backgroundAttachment", img.get("attachment", "inherit"));
}