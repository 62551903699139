import React, {useEffect, useRef} from "react";
import Velocity from "../../lib/Velocity";
import css from "./PageLoading.module.scss";

const PageLoading = function(props) {

  var icon = useRef(null);

  useEffect(() => {
    var iconEl = icon.current;
    Velocity(iconEl, "stop");
    Velocity(iconEl, {rotateZ: 360}, {duration: 500, loop: true, easing: "linear"});    
    return () => {
      Velocity(iconEl, "stop");
    }
  }, []);

  let styles = {
    height: props.height
  }
  return (
    <div className={css.module} style={styles}>
      <div className={css.inner}>
        <div ref={icon} className={css.icon} />
      </div>
    </div>
  )
}

PageLoading.defaultProps = {
  height: "auto"
}

export default PageLoading;