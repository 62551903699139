import {optimalResURL} from "../../../lib/MediaTools";
import {Map, List} from "immutable";
import {groupStyles, marginStyles, applyStyles, dimStyles} from "../../ModuleStyles";

export default function DynResImage(props) {

  // This expects an image with double resolution to have extension @2x.
  let {data} = props;
  var optimalSrc = getUrl(data);
  var styles = getStyles(data).toJS();
  return (
    <img src={optimalSrc} alt="" style={styles} onClick={(e) => handleClick(e, props)} title={data.get("title")} />
  );

}

function getUrl(data) {
  // If we force not to use retina, then just use the default src.
  if (data.get("retina", true) === false) return data.get("url");
  return optimalResURL(data.get("url"));
}


function getStyles(data) {
  return applyStyles(
      groupStyles(
        dimStyles(), 
        List(["maxWidth", "maxHeight", "display", "width"]), 
        marginStyles()
      ), 
      // base styles.
      data.get("styles", Map()),
      // Default styles
      Map({maxWidth: "inherit", width: "100%", display: "block"})
    )
}

function handleClick(e, props) {
  let {data, pageActions, router} = props;
  if (data.has("link")) {
    router.push(
      pageActions.resolveLink(
        data.getIn(["link", "link"]), 
        data.getIn(["link", "scope"])
      )
    );
  }
}
