import React from "react";
import TextInputField from "../../components/forms/TextInputField";
import FormError from "../../components/forms/FormError";
import ActionButton from "../../components/forms/ActionButton";
import {Field, reduxForm} from "redux-form/immutable";

const AuthForm = (props) => {

  let {errors, handleSubmit, submitting, submitLabel, standbyLabel, placeholder} = props;
  let label = submitting ? standbyLabel : submitLabel; 

  return (
    <form className="AuthForm" onSubmit={handleSubmit}>
      <div className="fields">
        <Field
          name="passcode"
          obscured={true}
          placeholder={placeholder}
          autoComplete="current-password"
          component={TextInputField}
          />
      </div>
      <div className="buttons">
        <FormError error={errors._error} />
        <ActionButton onClick={handleSubmit(values => props.onSubmit(values))} label={label} active={!submitting} primary={true} />
      </div>        
    </form>        
  )

}

const ReduxForm = reduxForm({
  form: "AuthForm",
  submitLabel: "Sign In",
  standbyLabel: "Standby...",
  placeholder: "Enter Passcode"
})(AuthForm);

export default ReduxForm;