import React from "react";
import VideoUtils from "../../../lib/VideoUtils";
import {Map, List} from "immutable";
import {marginStyles, paddingStyles, applyStyles, groupStyles, applyAbsoluteMeasurements} from "../../ModuleStyles";
import {cx} from "../../../lib/ClassSet";
import {canPlayBackgroundVideo} from "../../../lib/FeatureDetection";
import Dimensions from "../../../lib/Dimensions";
import {createModuleFromId} from "../../ModuleFactory";
import VideoPlayer from "../video_player/VideoPlayer";
import "./LayoutContainer.scss";

export default function LayoutContainer(props) {

  let {width, height, data, modules, moduleId, pageActions} = props;
  let moduleOrder = data.get("moduleOrder", List());
  let elements = moduleOrder.map((moduleId) => {
    return createModuleFromId(moduleId, modules, width, height, pageActions)
  })

  let styles = getStyles(data, width, height);
  let innerStyles = getInnerStyles(data).toJS();

  let classes = appendClassName(data, appendBackgroundVideoOverlayClass(data, cx({
    "LayoutContainer": true,
    "LayoutContainer withBgVideo": canUseBackgroundVideo(data)
  }), data));

  if (styles.has("@preserveRatio")) {
    let dims = Dimensions.fromString(styles.get("@preserveRatio"));
    let newHeight = Math.round(dims.transformW(width).h);
    if (!isNaN(newHeight)) {
      styles = styles.set("height", newHeight).remove("@preserveRatio");
    }
  }

  return (
    <div className={classes} style={styles.toJS()} id={moduleId}>
      {renderBorders(data)}
      {renderBackgroundVideo(data)}
      <div className="inner" style={innerStyles}>
        {elements}
      </div>
    </div>
  )
}

function canUseBackgroundVideo(data) {
  if (!canPlayBackgroundVideo(data)) return false;
  if (data.hasIn(["styles", "@backgroundVideo"])) return true;
  return false;
}

function appendClassName(data, classes) {
  if (data.has("className")) {
    return `${classes} ${data.get("className")}`
  }
  return classes;
}

function appendBackgroundVideoOverlayClass(data, classes) {
  if (!canUseBackgroundVideo(data)) return classes;
  let overlay = data.getIn(["styles", "@backgroundVideo", "overlay"], "");
  if (overlay === "") return classes;
  return `${classes} video-overlay video-overlay-${overlay}`;
}

function renderBackgroundVideo(data) {
  // TODO: Refactor this to be used by all layout components.
  if (!canUseBackgroundVideo(data)) return null;
  let bgVideo = data.getIn(["styles", "@backgroundVideo"]);
  let videoOptions = {
    url: VideoUtils.getComposedVideoUrl(bgVideo),
    poster: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
  }
  return <VideoPlayer key="video" 
          visible={true} 
          options={videoOptions} 
          width="100%" 
          height="100%"
          autoPlay={true}
          controls={false}
          loop={true} 
          muted={true} 
          fill={true}
          fit={bgVideo.get("fit", false)} />
}

function addBackgroundImageStyle(data, styles = List()) {
  if (!canUseBackgroundVideo(data)) return styles.push("@backgroundImages");
  return styles;
}

function getStyles(data, width, height) {
  // TODO: Investigage why display is "inline-block" by default.
  // Note: 17 Nov 2021. Changing default to display: block
  let defaultStyles = Map({position: "relative", display: "block", width: "100%"});
  let additionalStyles = addBackgroundImageStyle(data, List(["@preserveRatio", "@flex", "backgroundColor", "color", "textAlign", "minHeight", "minWidth", "width", "height", "maxWidth", "maxHeight", "display", "position", "alignItems", "top", "bottom", "left", "right", "overflow", "zIndex", "borderRadius"]));
  return applyAbsoluteMeasurements(
    applyStyles(
      groupStyles(
        marginStyles(), 
        paddingStyles(), 
        additionalStyles
      ), 
      data.get("styles"), 
      defaultStyles
    ),
    width,
    height
  )
}

function getInnerStyles(data) {
  // marginStyles need to be added last, as it may change how biased the aligntment is.
  return applyStyles(groupStyles(paddingStyles(), List(["@flexWide", "maxWidth", "minHeight", "width", "height", "boxShadow"]), marginStyles()), data.getIn(["styles", "#inner"]));
}

function renderBorders(data) {
  let dataStyles = data.get("styles", Map());
  if (!dataStyles.has("#border")) return null;
  let border = dataStyles.get("#border");
  let offset = border.get("offset", "0px");
  let styles = {
    position: "absolute",
    top: offset,
    right: offset,
    bottom: offset,
    left: offset,
    borderWidth: border.get("thickness", "1px"),
    borderStyle: border.get("style", "solid"),
    borderColor: border.get("color", "inherit")
  }
  return (
    <div className="DecoratedBorder" style={styles} />
  )
}