import {cx} from "../../../lib/ClassSet";

export default function Dot(props) {
  let classes = cx({
    "item": true,
    "s-selected": props.selected,
    "s-not-selected": !props.selected
  })

  return (
    <div className={classes} onClick={props.onClick}>
      <div className="icon" />
    </div>
  )
}