import React from "react";
import Section from "./Section";
import "./FloorOverview.scss";

export default function FloorOverview({data, title, floorId, onClick, getImageUrl}) {
 
  if (data.size === 0) return null;
  return (
    <div className="GalleryFloorOverview">
      <div className="title">{title}</div>
      <div className="sections">
        {data.map((section, index) => (
          <Section
            key={index}
            floorId={floorId}
            sectionId={section.get("id")}
            images={section.get("images")}
            label={section.get("title")}
            onClick={onClick}
            getImageUrl={getImageUrl}
            />
        ))}
      </div>
    </div>
  )
}