import {Map, List, Set} from "immutable";

export const DEFAULT_PAGE_HEADER = Map({
  title: "World's Finest International",
  synopsis: "Luxury Real Estate, Exquisite Escapes and Unforgettable Experiences"  
})

export function getElementById(id="") {
  try {
    // Wrap in try...catch as something document is not available (e.g. server rendering)
    return document.getElementById(id);
  } catch(e) {
    return null;
  }
}

export function setWindowClass(className="") {
  if (className === "") return;
  try {
    // Wrap in try...catch as something document is not available (e.g. server rendering)
    var el = document.getElementsByTagName("html")[0];  
    let classes = Set(String(el.getAttribute("class")).split(" "))
    let updatedClasses = classes.add(`bg-${className}`);
    el.setAttribute("class", updatedClasses.toList().join(" ")); 
  } catch(e) {
  }
}

export function clearWindowClass(className="") {
  if (className === "") return;
  try {
    var el = document.getElementsByTagName("html")[0];  
    let classes = Set(String(el.getAttribute("class")).split(" "))
    let updatedClasses = classes.delete(`bg-${className}`);
    el.setAttribute("class", updatedClasses.toList().join(" "));   
  } catch(e) {
  }
}

export function getScrollableElement() {
  try {
    // Wrap in try...catch as something document is not available (e.g. server rendering)
    // return document.getElementsByClassName("Pages")[0];
    // New Safari overflow/toolbar issue forces us to use HTML.
    return document.getElementsByTagName("html")[0];
  } catch(e) {
    return null;
  }
}

export function enhancePageHeader(header, data=Map()) {
  return List(["title", "synopsis"]).reduce((reduction, key) => {
    if (data.has(key)) {
      return reduction.set(key, data.get(key));
    }
    return reduction;
  }, header);  
}

export function updatePageHeader(header = Map()) {
  try {
    document.getElementsByTagName("title")[0].text = header.get("title");
    getPageMetaTag("title").setAttribute("content", header.get("title"));
    getPageMetaTag("description").setAttribute("content", header.get("synopsis"));
  } catch(e) {
    // Do nothing. It will error out when rendering on a server.
  }
}

function getPageMetaTag(name) {
  let metas = List(document.getElementsByTagName("meta"));
  return metas.find(x => x.getAttribute("name") === name);
}