import React, {useState, useEffect} from "react";
import {cx} from "../lib/ClassSet";
import {useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {getNavigation} from "../reducers/uiReducer";
import MenuTrigger from "./MenuTrigger";
import usePrevious from "../hooks/usePrevious";
import Conditional from "./Conditional";
import {showWfBranding} from "../config";
import {Map} from "immutable";
import "./NavigationMenu.scss";

const NavigationMenu = (props) => {
  const [expanded, setExpanded] = useState(false)
  const navigation = useSelector(state => getNavigation(state.get("ui"), props.pageId));
  const authorized = useSelector(state => state.getIn(["auth", "authorized"]));
  const mobile = useSelector(state => state.getIn(["ui", "mobileMode"]));
  const location = useLocation();
  const oldPathname = usePrevious(location.pathname);

  useEffect(() => {
    if (expanded && (oldPathname !== location.pathname)) {
      setExpanded(false);
    }
  }, [expanded, location.pathname, oldPathname])

  if (!authorized) return null;

  let items = navigation.get("navigation", Map()).map((x) => {
    return (
      <Link to={x.get("url")} key={x.get("url")}>{x.get("title")}</Link>
    )
  }).toArray();

  function handleToggle(e) {
    setExpanded(!expanded);
  }

  let classes = cx({
    "NavigationMenu": true,
    "u-mobile": mobile,
    "s-open": expanded,
    "s-closed": !expanded
  })

  return (
    <div className={classes}>
      <MenuTrigger show={true} onClick={handleToggle} menuShowing={expanded} />
      <div className="panel">
        <div className="inner">
          <nav>
            {items}
          </nav>
          <Conditional show={showWfBranding()}>
            <div className="poweredBy">
              <a href="https://worldsfinest.com">Powered by <span>World's Finest</span></a>
            </div>
          </Conditional>
        </div>
      </div>
    </div>
  )
}

export default NavigationMenu;