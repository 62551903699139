import React, {useEffect} from "react";
import {isMetaLoading, isMetaLoaded} from "./MetaTools";
import PageLoading from "./components/PageLoading";
import PageNotFound from "./components/PageNotFound";
import DynamicPage from "./DynamicPage";
import {useDispatch, useSelector} from "react-redux";
import {getMeta} from "./reducers/pageReducer";
import {fetchPage} from "./actions/pageActions";


function DynamicPageLoader(props) {
  const meta = useSelector(state => getMeta(state.get("pages"), props.pageId));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPage(props.pageId, meta));
  }, [props.pageId, meta, dispatch]);

  if (isMetaLoading(meta)) {
    return <PageLoading height={props.height} />
  } else if (isMetaLoaded(meta)) {

    return (
      <DynamicPage
        width={props.width}
        height={props.height}
        pageId={props.pageId}
        slug={props.slug}
        baseUrl={props.baseUrl}
        exportStyles={props.exportStyles}
        exportPageHeader={props.exportPageHeader}
        />
    )
  }

  return <PageNotFound height={props.height} />

}

export default DynamicPageLoader;