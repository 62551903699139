import React, { Component } from "react";
import FieldError from "./FieldError";
import {cx} from "../../lib/ClassSet";
import "./TextInputField.css";

const noOp = () => null;

class TextInputField extends Component {

  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.state = {
      focused: false
    }
  }

  render() {
    const {serverError, label, active, maxLength, minLength, autoComplete, input: {value, onChange}, meta: {touched, error}} = this.props;    

    let changeHandler = active ? onChange : noOp;
    let classes = cx({
      "TextInput": true,
      "s-inactive": !active,
      "s-focused": this.state.focused,
      "s-obscured": this.props.obscured
    });

    let extras = {
      maxLength: maxLength,
      minLength: minLength
    }
    if (autoComplete) {
      extras.autoComplete = autoComplete
    }


    let inputType = this.props.obscured ? "password" : this.props.type;

    return (
      <div className="TextInputField field">
        <div className="label">{label}</div>
        <input className={classes} type={inputType} value={value} onChange={changeHandler} onFocus={this.handleFocus} onBlur={this.handleBlur} placeholder={this.props.placeholder} {...extras} />
        <FieldError serverError={serverError} clientError={error} showClientError={touched && error} />
      </div>
    )      
  }

  handleFocus(e) {
    e.target.select();
    this.setState({focused: true});
  }

  handleBlur(e) {
    this.setState({focused: false});
  }
}

TextInputField.defaultProps = {
  active: true,
  placeholder: "",
  obscured: false,
  maxLength: "none",
  minLength: "none",
  type: "text"
}

export default TextInputField;