import React from "react";
import ArticleWindow from "./ArticleWindow";
import {useDispatch, useSelector} from "react-redux";
import {closeArticlePopup} from "../../../actions/uiActions";

export default function ArticleWindowContainer({data}) {
  const width = useSelector(state => state.getIn(["ui", "width"]));
  const height = useSelector(state => state.getIn(["ui", "height"]));
  const dispatch = useDispatch();
  
  function onClose() {
    dispatch(closeArticlePopup());
  }

  return (
    <ArticleWindow
      pageId={data.get("pageId")}
      width={width}
      height={height}
      baseUrl=""
      slug={data.get("slug")}
      onClose={onClose}
      />
  )

}