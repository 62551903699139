import React from "react";
import GalleryFloorsMenu from "./GalleryFloorsMenu";
import GalleryFloorView from "./GalleryFloorView";
import GalleryViewTypes from "./GalleryViewTypes";
import GalleryMobileMenu from "./GalleryMobileMenu";
import RoomOverview from "./RoomOverview";
import {useLocation} from "react-router-dom";
import {decodeSeachParams, buildUrl} from "../../../lib/LocationTools";
import "./PropertyGalleryBrowser.scss";

export default function PropertyGalleryBrowser(props) {
  const location = useLocation();
  let floors = props.floors;
  let baseUrl = props.baseUrl;
  let selectedFloorId = getSelectedFloorId(floors, location)
  let selectedView = getSelectedView(location);
  let mobile = props.mobile;

  let styles = { 
    maxWidth: "1240px",
    margin: "auto",
    display: "flex"
  }
  let menuStyles = { width: "240px" }
  let width = Math.min(props.width, 1240);
  if (width <= 1240) {
    width -= 265; // 25 margin
  }
  if (mobile) {
    width = props.width - 30;
    menuStyles.width = "100%";
    menuStyles.display = "none";
    styles.display = "block";
    styles.paddingLeft = "15px";
  }

  function handleChangeFloor(floorId) {
    let params = decodeSeachParams(location);
    params.floor = floorId;
    params.view = "floor";
    window.location.replace(buildUrl(location, params));
  }

  function handleChangeView(view) {
    window.location.replace(buildUrl(location, {view: view}));    
  }

  function handleSectionClick(floorId, sectionId) {
    window.location.replace(buildUrl(location, {view: "floor", floor: floorId, section: sectionId}));    
  }

  function getImageUrl(src) {
    return `${baseUrl}${src}`;
  }

  let mobileMenu = null;
  if (mobile) {
    mobileMenu = (
      <GalleryMobileMenu
        show={mobile}
        selectedView={selectedView}
        selectedFloorId={selectedFloorId}
        data={floors}
        onChangeView={handleChangeView}
        onChangeFloor={handleChangeFloor}
        />
    )
  }


  if (selectedView === "floor") {
    return (
      <div className="PropertyGalleryBrowser view-floors" style={styles}>
        {mobileMenu}
        <div style={menuStyles}>
          <div className="GalleryViewTypes-wrapper">
            <GalleryViewTypes
              show={!mobile}
              selectedView={selectedView}
              onChange={handleChangeView}
              />
          </div>

          <GalleryFloorsMenu 
            data={floors} 
            selectedId={selectedFloorId} 
            onChange={handleChangeFloor}
            />
        </div>

        <div style={{width: width}}>
          <GalleryFloorView
            data={getFloorData(floors, selectedFloorId)}
            width={width}
            getImageUrl={getImageUrl}
            />
        </div>
      </div>
    )    
  } else {
    return (
      <div className="PropertyGalleryBrowser view-overview" style={styles}>
        {mobileMenu}
        <div style={menuStyles}>
          <div className="GalleryViewTypes-wrapper">
            <GalleryViewTypes
              show={!mobile}
              selectedView={selectedView}
              onChange={handleChangeView}
              />
          </div>           
        </div>

        <div style={{width: width}}>
          <RoomOverview
            data={floors}  
            onClick={handleSectionClick}
            mobile={mobile}    
            getImageUrl={getImageUrl}
            />
        </div>
      </div>
    )
  }
}

function getFloorData(floors, floorId) {
  return floors.find((floor) => floor.get("id") === floorId);
}

function getSelectedFloorId(floors, location) {
  let params = decodeSeachParams(location);
  return params.floor || floors.first().get("id");
}

function getSelectedView(location) {
  let params = decodeSeachParams(location);
  return params.view || "floor";
}
