import React from "react";
import BackgroundImage from "../../components/background_image/BackgroundImage";
import "./Section.scss";

export default function Section({images, label, floorId, sectionId, onClick, getImageUrl}) {
  return (
    <div className="GalleryFloorOverviewSection">
      <div className="thumbs" onClick={() => onClick(floorId, sectionId)}>
        {images.map((image, index) => (
          <BackgroundImage 
            className="thumb" 
            key={index} 
            url={getImageUrl(image.get("thumb"))} 
            />
        ))}
      </div>
      <div className="label">{label}</div>
    </div>
  )
}