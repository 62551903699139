import React from "react";
import {cx} from "../../../lib/ClassSet";
import {Range} from "immutable";
import Dot from "./Dot";
import "./ProgressDots.scss";

export default function ProgressDots(props) {

  let {total, index, active} = props;

  function handleClick(n) {
    if (index === n) return;
    if (props.onChange) {
      props.onChange(n);
    }    
  }

  let items = Range(0, total, 1).map(n => {
    return <Dot
            key={n}
            selected={n === index}
            onClick={() => handleClick(n)}
            />
  }).toArray();

  let classes = cx({
    "ProgressDots": true,
    "s-active": active,
    "s-not-active": !active
  })

  return (
    <div className={classes}>
      {items}
    </div>
  )
} 

ProgressDots.defaultProps = {
  total: 0,
  index: 0,
  active: true  
}