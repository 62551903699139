import React, {useRef} from "react";
import {Map} from "immutable";
import {cx} from "../../../lib/ClassSet";
import StringUtils from "../../../lib/StringUtils";
import "./CircularControls.scss";

export default function CircularControls(props) {

  let {total, current, nextSlide, prevSlide, styles, circular} = props;
  let rootRef = useRef(null);
  let backgroundColor = styles.get("backgroundColor", "#1B1C1C");
  let color = styles.get("color", "#B8A767");
  let simplified = total > 9; // Can't fit double numbers.
  let classes = cx({
    "CircularControls": true,
    "u-simplified": simplified
  })
  let label = simplified ? StringUtils.padNumber(current+1) : `${current+1}/${total}`; 
  let x = simplified ? 22.75 : 19.986;

  function handleClick(e) {
    let ref = rootRef.current;
    let rect = ref.getBoundingClientRect();
    let normalX = e.clientX - rect.left;
    if (normalX < 30 && hasPrev(circular, current, total)) prevSlide();
    if (normalX >= 30 && hasNext(circular, current, total)) nextSlide();
  }

  return (
    <svg className={classes} ref={rootRef} width="60px" height="60px" viewBox="0 0 60 60" onClick={handleClick}>
        <circle id="bg" stroke="none" fill={backgroundColor} fillRule="evenodd" cx="30" cy="30" r="30"></circle>
        {renderPrev(circular, current, total, prevSlide, color)}          
        {renderNext(circular, current, total, nextSlide, color)}          
        <circle id="inner" stroke={color} strokeWidth="1" fill="none" cx="30" cy="30" r="16"></circle>
        <circle id="outer" stroke={color} strokeWidth="0.5" fill="none" cx="30" cy="30" r="18"></circle>
        <text id="label" stroke="none" fill="none">
          <tspan id="text" x={x} y="34" fill={color}>{label}</tspan>
        </text>
    </svg>        
  )

}

CircularControls.defaultProps = {
  circular: false,
  current: 0,
  total: 0,
  styles: Map()
}

function hasPrev(circular, current, total) {
  if (circular && total > 1) return true;
  return current > 0;
}

function hasNext(circular, current, total) {
  if (circular && total > 1) return true;
  return (current+1 <= total-1);
}

function renderNext(circular, current, total, action, color) {
  if (hasNext(circular, current, total)) {
    return <polygon id="next" stroke="none" fill={color} fillRule="evenodd" transform="translate(53.000000, 30.000000) scale(-1, 1) translate(-53.000000, -30.000000) " points="55 34 51 30 55 26"></polygon>
  }
  return null;
}

function renderPrev(circular, current, total, action, color) {
  if (hasPrev(current, total)) {
    return <polygon id="prev" stroke="none" fill={color} fillRule="evenodd" points="9 34 5 30 9 26"></polygon>
  }
  return null;
}
