import {Map} from "immutable";
import {stringify} from "./StringKeyConverter";

function buildPayload(method, options=Map()) {

  let data = {
    method: method.toUpperCase(),
    mode: "cors",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    }
  }  

  if (options.has("token")) {
    data.headers["Authorization"] = `Bearer ${options.get("token")}`
  }

  if (options.has("payload")) {
    data.body = stringify(options.get("payload"));
  }

  if (process.env.NODE_ENV !== "production") {
    data.headers["X-Branch"] = "head";
  }

  return data;
}

export function buildPostPayload(payload) {
  return buildPayload("POST", Map({
    payload: payload
  }));
}

export function buildAuthPostPayload(token, payload) {
  return buildPayload("POST", Map({
    payload: payload,
    token: token
  }));
}

export function buildPutPayload(payload) {
  return buildPayload("PUT", Map({
    payload: payload
  }));
}

export function buildAuthPutPayload(token, payload) {
  return buildPayload("PUT", Map({
    payload: payload,
    token: token
  }));
}

export function buildGetPayload() {
  return buildPayload("GET");
}

export function buildAuthGetPayload(token) {
  return buildPayload("GET", Map({
    token: token
  }));
}