import React from "react";
import {withRouter} from "react-router-dom";
import {applyStyles} from "../ModuleStyles";
import {Map, List} from "immutable";

const PagesUnderlay = (props) => {

  var defaultStyles = Map({
    width: props.width,
    height: props.height
  })

  let underlayStyles = defaultStyles;

  if (props.location.pathname === props.pageStylesPathname) {
    let additionalStyles = List(["@backgroundImages", "backgroundColor"]);
    underlayStyles = applyStyles(additionalStyles, props.pageStyles, defaultStyles);
  }

  return (
    <div className="PagesUnderlay" style={underlayStyles.toJS()} />
  )

}

export default withRouter(PagesUnderlay);