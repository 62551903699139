import {useSelector} from 'react-redux';
import PageLinks from "./PageLinks";
import {getNavigation} from "../../reducers/pageReducer";

export default function PageLinksContainer(props) {
  const navigation = useSelector((state) => {
    return getNavigation(state.getIn(["ui"]), props.pageActions.baseUrl);
  })

  return <PageLinks navigation={navigation} {...props} />
}
