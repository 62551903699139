import marked from "marked";

export function convertUrlsToLinks(str) {
  /* eslint-disable no-useless-escape */
  return str.replace(/((\w+:\/\/)[-a-zA-Z0-9:@;?&=\/%\+\.\*!'\(\),\$_\{\}\^~\[\]`#|]+)/g, "<a href=\"$&\" target=\"_blank\">$&</a>");  
}

export function fromMarkdown(str) {
    marked.setOptions({
      breaks: true
    });    
    return marked(str);  
}