import React from "react";
import {useRef} from "react";
import Velocity from "../../../lib/Velocity";
import {applyStyles, groupStyles, marginStyles, positionStyles} from "../../ModuleStyles";
import {Map} from "immutable";
import FXReveal from "../fx_reveal/FXReveal";
import {optimalResURL} from "../../../lib/MediaTools";
import "./ViewVideoButton.scss";

export default function ViewVideoButton(props) {
  let cover = useRef(null);
  let {data, pageActions} = props;
  let title = data.get("title", "View Video");
  let subtitle = data.get("subtitle", "");

  let styles = applyStyles(
                 groupStyles(marginStyles(), positionStyles()),
                 data.get("styles", Map())
                ).toJS();

  let coverStyles = getCoverStyles(data).toJS();


  function handleClick(e) {
    e.preventDefault();
    pageActions.showVideoPlayer(
      data.getIn(["video", "url"]), 
      data.getIn(["video", "formats"]),
      data.getIn(["video", "dims"])
    )
  }

  function handleMouseEnter(e) {
    var el = cover.current;
    Velocity(el, "stop");
    Velocity(el, {scale: 1.2}, {duration: 200});
  }

  function handleMouseLeave(e) {
    var el = cover.current;
    Velocity(el, {scale: 1}, {duration: 100});    
  }


  let el = (
    <div className="ViewVideoButton" style={styles} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
      <div className="cover" ref={cover} style={coverStyles}>
        <svg id="PlayButton" width="40px" height="40px" viewBox="0 0 144 144" version="1.1"> 
          <g stroke="none" strokeWidth="4" fill="none" fillRule="evenodd">
              <circle id="bg" stroke="#FFFFFF" cx="72.5" cy="72.5" r="65.5"></circle>
              <polygon id="play" fill="#FFFFFF" transform="translate(73.621482, 72.085400) rotate(90.000000) translate(-73.621482, -72.085400) " points="73.621486 53.1708007 97.621486 87.1708007 49.621486 87.1708007 "></polygon>
          </g>
        </svg>              
      </div>
      <div className="text">
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
    </div>
  )

  if (!data.has("fx")) return el;
  return React.createElement(FXReveal, data.get("fx").toJS(), el);

}

function getCoverStyles(data) {
  if (!data.hasIn(["cover", "url"])) return Map();
  let url = getUrl(data.get("cover"));

  return Map().set("backgroundImage", `url(${url})`)
              .set("backgroundSize", "cover")
              .set("backgroundPosition", "50% 50%");
}

function getUrl(image) {
  // If we force not to use retina, then just use the default src.
  if (image.get("retina", false) === false) return image.get("url");
  return optimalResURL(image.get("url"));
}