export function decodeSeachParams(location) {
  let searchParams = location.hash.substr(1).split("&");
  return searchParams.reduce((acc, item) => {
    let a = item.split("=");
    return {...acc, [a[0]]: a[1]};
  }, {});  
}

export function encodeSearchParams(params) {
  var result = [];
  for (var key in params) {
    if (params[key]) result.push(`${key}=${params[key]}`)
  } 
  return result.join("&");
}

export function buildUrl(location, searchParams = {}) {
  let encodedParams = encodeSearchParams(searchParams);
  let strSearch = (encodedParams === "") ? "#" : `#${encodedParams}`;
  return `${location.pathname}${strSearch}`;
}