function errorsToError(error) {
  if (error.errors) return error.errors;
  return error.error;
}

export function BadRequestException() {
  this.code = 400;
  this.name = "BadRequestException";
  this.message = "The request is not valid."
}

export function BadRequestExceptionWithError(error) {
  this.code = 400;
  this.error = errorsToError(error);
  this.name = "BadRequestExceptionWithError";
  this.message = "The request is not valid."
}

export function UnprocessableEntity(error) {
  this.code = 400;
  this.error = errorsToError(error);
  this.name = "BadRequestExceptionWithError";
  this.message = "The request is not valid."
}

export function AuthenticationError() {
  this.code = 401;
  this.name = "AuthenticationError";
  this.message = "Invalid credentials.";
}

export function UserSuspendedException() {
  this.code = 401;
  this.name = "UserSuspendedException";
  this.message = "You account has been suspended.";
}

export function InvalidTokenException() {
  this.code = 401;
  this.name = "InvalidTokenException";
  this.message = "The access token is not valid.";
}

export function ExpiredTokenException() {
  this.code = 401;
  this.name = "ExpiredTokenException";
  this.message = "The access token has expired, request a new one.";
}

export function ExpiredRefreshTokenException() {
  this.code = 401;
  this.name = "ExpiredRefreshTokenException";
  this.message = "The refresh token has expired or is invalid.";
}

export function NotFoundException() {
  this.code = 404;
  this.name = "NotFoundException";
  this.message = "The resource was not found.";
}

export function ServerErrorException() {
  this.code = 500;
  this.name = "ServerErrorException";
  this.message = "An unknown error happened on the server.";
}