export function createRequestTypes(base) {
  return {
    LOAD: `${base}_LOAD`,
    LOADING: `${base}_LOADING`,
    SUCCESS: `${base}_SUCCESS`,
    FAILED: `${base}_FAILED`
  }
}

export function createSubmitRequestTypes(base) {
  return {
    SUBMIT: `${base}_SUBMIT`,
    SUBMITTING: `${base}_SUBMITTING`,
    SUCCESS: `${base}_SUCCESS`,
    FAILED: `${base}_FAILED`,
    CANCEL: `${base}_CANCEL`
  }
}
