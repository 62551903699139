import React, {useRef, useEffect} from "react";
import Velocity from "../../lib/Velocity";
import "./Loading.scss";

export default function Loading({active = true}) {
  let ref = useRef(null);

  function startSpinning() {
    Velocity(ref.current, "stop");
    Velocity(ref.current, {rotateZ: 360}, {duration: 500, loop: true, easing: "linear"});    
  }

  function stopSpinning() {
    if (ref.current) {
      Velocity(ref.current, "stop");
    }
  }

  useEffect(() => {
    if (active) {
      startSpinning();
      return function() {
        stopSpinning();
      }
    } else {
      stopSpinning();
    }
  }, [active])


  return (
    <div className="Loading">
      <div className="inner">
        <div ref={ref} className="icon" />
      </div>
    </div>
  )

}