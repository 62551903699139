import React from "react";
import {Link} from "react-router-dom";
import css from "./PageNotFound.module.scss";

const PageNotFound = (props) => {

  let styles = {
    height: props.height
  }

  return (
    <div className={css.module} style={styles}>
      <div className={css.inner}>
        <div className={css.pretitle}>Our sincere apologies</div>
        <h1>SOMETHING’s NOT RIGHT</h1>
        <p>Unfortunately we cannot find the page you requested. Please try pressing your back button or <Link to="/">click here to return to our landing page</Link></p>
      </div>
    </div>
  )

}

export default PageNotFound;