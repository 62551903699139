import React, {Component} from "react";
import {cx} from "../../lib/ClassSet";
import "./ActionButton.css";


class ActionButton extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    if (!this.props.show) return null;
    let {label, action, active, formButton} = this.props;
    let classes = cx({
      "ActionButton": true,
      "formButton": formButton,
      "s-secondary": !this.props.primary,
      "s-primary": this.props.primary,
      "s-active": active,
      "s-inactive": !active,
      "s-link": this.props.link
    })
    let name = `${action}_button`;
    if (formButton) {
      return (
        <input className={classes} type="submit" name={name} value={label} />
      )
    } else {
      return (
        <div className={classes} onClick={this.handleClick}>
          <div className="label">{label}</div>
        </div>      
      )
    }
  }

  handleClick(e) {
    if (!this.props.active) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.props.onClick(e);
  }
}

ActionButton.defaultProps = {
  label: "label",
  primary: false,
  active: true,
  action: "submit",
  formButton: false,
  link: false,
  show: true
}

export default ActionButton;