import React from "react";
import MenuItem from "./MenuItem";
import "./GalleryFloorsMenu.scss";

export default function GalleryFloorsMenu({data, selectedId, onChange}) {
  
  return (
    <div className="GalleryFloorsMenu">
      {data.map((floor, index) => {
        if (floor.get("sections").size === 0) return null;
        return (
          <MenuItem 
            key={floor.get("id")} 
            id={floor.get("id")}
            title={floor.get("title")} 
            selected={floor.get("id") === selectedId} 
            onClick={onChange} />
        )
      })}
    </div>
  )
}

GalleryFloorsMenu.defaultProps = {
  selectedId: ""
}