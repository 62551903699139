import React, {useRef} from "react";
import Velocity from "../../../lib/Velocity";

export default function CloseIcon(props) {

  let closeIcon = useRef(null);

  function handleClick(e) {
    e.preventDefault();
    props.onClick();    
  }

  function handleMouseOver(e) {
    Velocity(closeIcon.current, "stop");
    Velocity(closeIcon.current, { rotateZ: [90,0] }, {duration: 200 });
  }

  function handleMouseOut(e) {
    Velocity(closeIcon.current, "stop");
    Velocity(closeIcon.current, { rotateZ: 0 }, {duration: 300 });
  }

  return (
    <div className="CloseIcon" onClick={handleClick} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} >
      <svg ref={closeIcon} id="closeIcon" width="25" height="25" viewBox="0 0 25 25">
        <g id="icon" strokeWidth="2" stroke="#000000" strokeLinecap="round" strokeLinejoin="round">
         <line x1="5" y1="5" x2="20" y2="20" />
         <line x1="5" y1="20" x2="20" y2="5" />
        </g>
      </svg>       
    </div>
  )

}