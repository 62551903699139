import React, {Component} from "react";
import GalleryFloorsMenu from "./GalleryFloorsMenu";
import {cx} from "../../../lib/ClassSet";
import "./GalleryMobileMenu.scss";

class GalleryMobileMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showFloorSelector: false
    }
  }

  render() {
    if (!this.props.show) return null;
    return (
      <div className="GalleryMobileMenu MobileFilterMenu">
        {this.renderStatusView()}
        {this.renderSelectFloorView(this.state.showFloorSelector)}
      </div>
    )
  }

  renderStatusView() {
    let floorClasses = cx({
      "view": true,
      "floorView": true,
      "u-selected": this.props.selectedView === "floor"
    })
    let overviewClasses = cx({
      "view": true,
      "overviewView": true,
      "u-selected": this.props.selectedView === "overview"
    })

    let floorTitle = "View by Floor";
    if (this.props.selectedView === "floor") {
      floorTitle = this.getFloorTitle(this.props.selectedFloorId, this.props.data);
    }

    return (
      <div className="statusbar">
        <div className={floorClasses} onClick={this.handleChangeView.bind(this, "floor")}>
          <div className="icon" />
          <div className="label">{floorTitle} <div className="caret"><span className="innerCaret" /></div></div>
        </div>
        <div className={overviewClasses} onClick={this.handleChangeView.bind(this, "overview")}>
          <div className="icon" />
          <div className="label">All Rooms</div>
        </div>
      </div>
    )
  }

  renderSelectFloorView(show) {
    if (!show) return null;
    return (
      <div className="panel">
        <GalleryFloorsMenu 
          data={this.props.data} 
          selectedId={this.props.selectedFloorId} 
          onChange={this.handleChangeFloor.bind(this)}
          />
      </div>
    )
  }

  handleChangeView(view) {
    // If our view is already "floor" and the request for change is "floor"
    // then we trigger the change floor view.
    if (view === "floor") {
      if (this.props.selectedView === view) {
        //  Toggle
        this.setState({showFloorSelector: !this.state.showFloorSelector});        
      } else {
        this.setState({showFloorSelector: !this.state.showFloorSelector});        
        this.props.onChangeView(view);
      }
    } else {
      this.setState({showFloorSelector: false});
      this.props.onChangeView(view);
    }
  }

  handleChangeFloor(floorId) {
    this.props.onChangeFloor(floorId);
    this.setState({showFloorSelector: false});
  }

  getFloorTitle(floorId, data) {
    let item = data.find((floor) => floor.get("id") === floorId);
    return item.get("title");
  }

}

GalleryMobileMenu.defaultProps = {
  show: true
}

export default GalleryMobileMenu;