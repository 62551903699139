import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import VideoUtils from "../../../lib/VideoUtils";
import {cx} from "../../../lib/ClassSet";
import {closeVideoPlayer} from "../../../actions/uiActions";
import Vimeo from '@u-wave/react-vimeo';
import "./VideoPlayerWindow.scss";

const WAIT_TIME = 1000;

export default function VideoPlayerWindow({data}) {
  const [controlsShowing, setShowControls] = useState(false);
  const [focused, setFocus] = useState(false);
  const [autoHide, setAutoHide] = useState(false);
  const width = useSelector(state => state.getIn(["ui", "width"]));
  const height = useSelector(state => state.getIn(["ui", "height"]));
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const timerRef = useRef();
  
  useEffect(() => {
    if (autoHide === false && controlsShowing === true) {
      const id = window.setTimeout(() => {
                      setShowControls(false);
                      setAutoHide(false);
                    }, WAIT_TIME);
      timerRef.current = id;

      return () => {
        window.clearTimeout(timerRef.current);
      }
    }
  }, [focused, controlsShowing, autoHide]);

  function handleClose() {
    dispatch(closeVideoPlayer());
  }

  function handleMouseMove(e) {
    let focusTop = height * 0.15;
    let focusBottom = height * 0.85;

    let insideFocusArea = e.clientY > focusBottom || e.clientY < focusTop;
    if (insideFocusArea) {
      // If we are near the controls, show them and put in focus mode.
      setShowControls(true);
      setFocus(true);
      setAutoHide(true);
    } else {
      // We are not in focus range so we turn focus OFF
      // We showControls, as we always want to show to viewer when moving mouse.
      setShowControls(true);
      setFocus(false);
      setAutoHide(false);
    }
  }

  let styles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: width,
    height: height,
    backgroundColor: "#000"
  }
  let classes = cx({
    "VideoPlayerWindow": true,
    "VideoPlayerWindow--focus": focused,
    "VideoPlayerWindow--controls": controlsShowing
  })
  return (
    <div className={classes} style={styles} onMouseMove={handleMouseMove}>
      <div className="topVignette vignette" />
      <div className="LogoContainer"/>
      <div className="close_video" onClick={handleClose}>Close</div>

      <Vimeo 
        key="video" 
        ref={videoRef}
        video={data.get("url")}
        width={width}
        height={height}
        onEnd={handleClose}
        autoplay
        />
    </div>
  )
}