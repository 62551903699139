import {Map, fromJS} from "immutable";
import {SET_WINDOW_SIZE, 
  CLEAR_NAVIGATION, 
  SET_NAVIGATION,
  EXPORT_PAGE_STYLES, 
  SET_PAGE_INFO} from "../actions/uiActions";

const MOBILE_WIDTH = 500;

const initialState = fromJS({
  pageStyles: Map(),
  pageStylesPathname: "",
  mobileMode: false,
  navigation: Map(),
  windowWidth: 800,
  windowHeight: 500,
  pageHeader: Map(),
  pageOffset: 0,
  // This is the width and height of the main page container.
  width: 800, 
  height: 500
});

// const MENU

const reducer = function(state = initialState, action) {
  switch(action.type) {

    case SET_PAGE_INFO:
      // Should this not be integrated with setNavigation?
      return state.set("pageHeader", action.header);

    case EXPORT_PAGE_STYLES:
      // These styles need to be immutable.
      return state.set("pageStyles", action.styles)
                  .set("pageStylesPathname", action.pathname);

    case SET_WINDOW_SIZE:
      return updateSizing(state, action.width, action.height);

    case SET_NAVIGATION:
      return setNavigation(state, action.navigation, action.title, Map(action.meta), action.path);

    case CLEAR_NAVIGATION:
      return state.deleteIn(["navigation", action.path]);

    default:
      return state;
  }
}

function setNavigation(state, navigation, title, meta, path) {
  let nav = Map({
    navigation: fromJS(navigation),
    title: title,
    meta: Map(meta)
  })
  return state.setIn(["navigation", path], nav);
}

export function getNavigation(state, path="") {
  // "/experiences/events/hahnenkamm/book"
  // "/experiences/events/hahnenkamm"
  // "/experiences/events"
  // "/experiences"
  let segments = path.split("/");
  if (state.hasIn(["navigation", path])) {
    return state.getIn(["navigation", path]);
  } else if (segments.length > 1) {
    segments.pop();
    return getNavigation(state, segments.join("/"));
  } else {
    return Map();
  }
}

function updateSizing(state, width, height) {
  let mobileMode = width < MOBILE_WIDTH;
  return state.set("windowWidth", width)
              .set("windowHeight", height)
              .set("width", width)
              .set("height", height)
              .set("mobileMode", mobileMode);
}

export default reducer;