import React from "react";
import {useSelector} from "react-redux";
import ArticleWindowContainer from "../pages/components/article_window/ArticleWindowContainer";
import VideoPlayerWindow from "../pages/components/video_player_window/VideoPlayerWindow";
import {cx} from "../lib/ClassSet";
import "./PopupContainer.scss";

export default function PopupContainer(props) {
  const data = useSelector((state) => state.get("popups"));
  let active = data.get("active");

  let popupElement = null;
  if (active) {
    switch(data.get("type")) {
      case "articleWindow":
        popupElement = <ArticleWindowContainer key="articleWindow" data={data.get("info")} />
        break;

      case "videoPlayerWindow":
        popupElement = <VideoPlayerWindow key="videoPlayerWindow" data={data.get("info")} />
        break;

      default:
        console.warn(`No popup type called ${data.get("type")}`);
    }
  }

    var classes = cx({
      "PopupContainer": true,
      "PopupContainer--active": active
    })

      // <VelocityTransitionGroup runOnMount={true} className={classes} enter={transition.enter} leave={transition.leave} component="div">
    return (
      <div className={classes}>
        {popupElement}
      </div>
    );


}