import React, {Component} from "react";
import gsap from "gsap";
import "./MenuTrigger.scss";

const didShow = (prevProps, nextProps) => (!prevProps.menuShowing && nextProps.menuShowing);
const didClose = (prevProps, nextProps) => (prevProps.menuShowing && !nextProps.menuShowing);

class MenuTrigger extends Component {

  constructor(props) {
    super(props);
    this.line1 = React.createRef();
    this.line2 = React.createRef();
    this.line3 = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  componentDidMount() {
    if (this.props.menuShowing) {
      this.onShow();
    } 
  }

  componentDidUpdate(prevProps) {
    if (didShow(prevProps, this.props)) {
      this.onShow();
    } else if (didClose(prevProps, this.props)) {
      this.onHide();
    }
  }

  onShow() {
    gsap.to(this.line1.current, {duration: 0.3, attr: {x1: 16, x2: 34, y1: 20, y2: 35}})
    gsap.to(this.line2.current, {duration: 0.1, opacity: 0, attr: {x1: 18, x2: 27}})
    gsap.to(this.line3.current, {duration: 0.3, attr: {x1: 16, x2: 34, y1: 35, y2: 20}})
  }

  onHide() {
    gsap.to(this.line1.current, {duration: 0.3, attr: {x1: 12, x2: 38, y1: 21, y2: 21}})
    gsap.to(this.line2.current, {duration: 0.1, opacity: 1, attr: {x1: 12, x2: 38}})
    gsap.to(this.line3.current, {duration: 0.3, attr: {x1: 12, x2: 38, y1: 33, y2: 33}})
  }


  render() {
    var oStyles = {};
    // if (!this.props.show) oStyles.display = "none";
    // let classes = [styles.module];
    // if (this.props.menuShowing) classes.push(styles.fixed);
    return (
      <div className="MenuTrigger" style={oStyles} onClick={this.handleClick}>
        <svg id="icon" width="50" height="50" viewBox="0 0 50 50">
          <g id="grp" strokeWidth="2" stroke="#FFFFFF" opacity="1">
           <line ref={this.line1} x1="12" y1="21" x2="38" y2="21" />
           <line ref={this.line2} x1="12" y1="27" x2="38" y2="27" />
           <line ref={this.line3} x1="12" y1="33" x2="38" y2="33" />
          </g>
        </svg>       
      </div>
    );
  }

  handleClick() {
    this.props.onClick();
  }

}

MenuTrigger.defaultProps = {
  show: false,
  menuShowing: false
}

export default MenuTrigger;