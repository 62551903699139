import {fromJS} from "immutable";
import {
  SHOW_ARTICLE_POPUP, CLOSE_ARTICLE_POPUP,
  SHOW_VIDEO_PLAYER, CLOSE_VIDEO_PLAYER
} from "../actions/uiActions";

const initialState = fromJS({
  active: false,
  type: "",
  info: {}
})

// const SuccessPopup = Record({
//   kind: "success",
//   title: "Success!",
//   message: "The action was successful.",
//   buttons: []
// });

// const ErrorPopup = Record({
//   kind: "error",
//   title: "Oops! An Error Occurred",
//   message: "The action failed.",
//   buttons: []
// })

const reducer = function(state = initialState, action) {
  switch(action.type) {

    case SHOW_ARTICLE_POPUP:
      return state.merge({type: "articleWindow", active: true, info: {
        pageId: action.pageId,
        slug: action.slug
      }});

    case SHOW_VIDEO_PLAYER:
      return state.merge({type: "videoPlayerWindow", active: true, info: action});      

    case CLOSE_ARTICLE_POPUP:
    case CLOSE_VIDEO_PLAYER:
      return state.set("active", false);

    default:
      return state;
  }
}

export default reducer;