import { takeEvery } from 'redux-saga/effects'
import {SET_AUTHORIZED} from "../actions/authActions";

/* eslint-disable */
function* doRecordUser(baseAction) {
  window.gtag("event", "login", {
    "event_category": "engagement",
    "event_label": "Logged In",
    "value": baseAction.user
  })
  return true;
}
/* eslint-enable */

export default function* watch() {
  yield takeEvery(SET_AUTHORIZED, doRecordUser);
}