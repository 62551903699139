export default function VideoPlaybackToggleButton(props) {

  return (
    <div className="PlaybackButton" onClick={props.onTogglePlayback}>
      {getPlaybackButton(props)}
    </div>  
  )

}

function getPlaybackButton(props) {
  if (props.playing) return getPauseButton();
  return getPlayButton();
}

function getPlayButton() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 10 10" version="1.1">
      <path id="play-svg-icon" d="M2,2 L8,5 L2,8 Z" fill="#000" />
    </svg>
  )    
}

function getPauseButton() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 10 10" version="1.1">
      <g id="pause-svg-icon" fill="#000">
        <path d="M2,2 l2,0 l0,6 l-2,0 Z" />
        <path d="M6,2 l2,0 l0,6 l-2,0 Z" />
      </g>
    </svg>
  )    
}

VideoPlaybackToggleButton.defaultProps = {
  playing: false
}