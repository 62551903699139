import React, {Component} from "react";
import Velocity from "../../../lib/Velocity";

const TIMER_INTERVAL = 300;
class FXReveal extends Component {

  constructor(props) {
    super(props);
    this.rootRef = React.createRef();
    this.state = {
      revealed: false
    }
  }

  componentDidMount() {
    var self = this;
    if (!this.props.enabled) return;
    this.intervalId = setInterval(() => {
      self.checkReveal();
    }, TIMER_INTERVAL);
  }

  componentWillUnmount() {
    this.clear();
  }

  clear() {
    clearInterval(this.intervalId);
  }

  checkReveal() {
    var bounds = this.rootRef.current.getBoundingClientRect();
    var middle = bounds.top + bounds.height/2;
    if (middle <= window.innerHeight && middle >= 0) {
      this.clear();
      this.reveal();
    }
  }

  getOpacityChange() {
    if (this.props.fade) return [1,0];
    return [1,1];
  }

  getEffect() {
    switch(this.props.effect) {
      case "slideRight":
        return { translateX: [0, `-${this.props.amount}`], opacity: this.getOpacityChange() }
      case "slideLeft":
        return { translateX: [0, this.props.amount], opacity: this.getOpacityChange() }
      case "slideUp":
        return { translateY: [0, this.props.amount], opacity: this.getOpacityChange() }
      case "slideDown":
        return { translateY: [0, `-${this.props.amount}`], opacity: this.getOpacityChange() }
      case "scaleX":
        return { scale: [1, 0], opacity: this.getOpacityChange() }
      default: 
        return { opacity: this.getOpacityChange() }
    }    
  }

  reveal() {
    var self = this;
    Velocity(
      this.rootRef.current, 
      this.getEffect(),
      { 
        duration: this.props.duration,
        delay: this.props.delay, 
        visibility: "visible",
        begin: (elements) => {
          self.setState({revealed: true});
        }
      }
    )
  }

  render() {
    var styles = {
      position: "relative",
      visibility: "hidden"
    }

    return (
      <div ref={this.rootRef} style={styles}>
        {this.props.children}
      </div>
    )
  }

}

FXReveal.defaultProps = {
  enabled: true,
  fade: true,
  effect: "fadeIn",
  amount: "100%",
  duration: 1000,
  delay: 0
}

export default FXReveal;