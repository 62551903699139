import {Map} from "immutable";

export const SET_WINDOW_SIZE = "SET_WINDOW_SIZE";
export const CLEAR_NAVIGATION = "CLEAR_NAVIGATION";
export const SET_NAVIGATION = "SET_NAVIGATION";
export const EXPORT_PAGE_STYLES = "EXPORT_PAGE_STYLES";
export const SET_PAGE_INFO = "SET_PAGE_INFO";
export const SHOW_ARTICLE_POPUP = "SHOW_ARTICLE_POPUP";
export const CLOSE_ARTICLE_POPUP = "CLOSE_ARTICLE_POPUP";
export const SHOW_VIDEO_PLAYER = "SHOW_VIDEO_PLAYER";
export const CLOSE_VIDEO_PLAYER = "CLOSE_VIDEO_PLAYER";

export function setPageInfo(header) {
  return {
    type: SET_PAGE_INFO,
    header
  }
}

export function exportPageStyles(styles, pathname) {
  return {
    type: EXPORT_PAGE_STYLES,
    styles, pathname
  }
}

export function setWindowSize(width, height) {
  return {
    type: SET_WINDOW_SIZE,
    width, height
  }
}

export function setNavigation(meta, navigation, title="", path) {
  return {
    type: SET_NAVIGATION,
    meta, title, navigation, path
  }
}

export function clearNavigation(path = "") {
  return {
    type: CLEAR_NAVIGATION,
    path
  }
}

export function showArticlePopup(pageId, slug="") {
  if (Map.isMap(pageId)) {
    return {
      type: SHOW_ARTICLE_POPUP,
      pageId: pageId.get("pageId"),
      slug: pageId.get("slug", "")
    }
  }
  return {
    type: SHOW_ARTICLE_POPUP,
    pageId, slug
  }
}

export function closeArticlePopup() {
  return {
    type: CLOSE_ARTICLE_POPUP
  }
}

export function showVideoPlayer(url, formats, dims) {
  return {
    type: SHOW_VIDEO_PLAYER,
    url, formats, dims
  }
}

export function closeVideoPlayer() {
  return {
    type: CLOSE_VIDEO_PLAYER
  }
}
