import React, {useRef} from "react";
import Velocity from "../../../lib/Velocity";
import {applyStyles, groupStyles, marginStyles, positionStyles, dimStyles} from "../../ModuleStyles";
import {Map} from "immutable";
import FXReveal from "../fx_reveal/FXReveal";
import "./ViewVimeoIconButton.scss";


export default function ViewVimeoIconButton(props) {
  let {data, pageActions} = props;
  let mainRef = useRef(null);
  let classNames = `ViewVimeoIconButton u-theme-${data.get("theme", "dark")}`;

  let styles = applyStyles(
                groupStyles(marginStyles(), positionStyles(), dimStyles()), 
                data.get("styles", Map())
              ).toJS(); 

  function handleMouseEnter(e) {
    Velocity(mainRef.current, "stop");
    Velocity(mainRef.current, {scale: 1.2}, {duration: 200});
  }

  function handleMouseLeave(e) {
    Velocity(mainRef.current, {scale: 1}, {duration: 100});    
  } 

  function handleClick(e) {
    e.preventDefault();
    pageActions.showVideoPlayer(
      data.getIn(["video", "id"], data.getIn(["video", "url"])), 
      data.getIn(["video", "formats"], []),
      data.getIn(["video", "dims"])
    )    
  }

  let el = (
    <div className={classNames} ref={mainRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={styles} onClick={handleClick}>
      <svg id="PlayButton" width="100%" height="100%" viewBox="0 0 144 144" version="1.1"> 
        <g stroke="none" strokeWidth="4" fill="none" fillRule="evenodd">
            <circle id="bg" stroke="#FFFFFF" cx="72.5" cy="72.5" r="65.5"></circle>
            <polygon id="play" fill="#FFFFFF" transform="translate(73.621482, 72.085400) rotate(90.000000) translate(-73.621482, -72.085400) " points="73.621486 53.1708007 97.621486 87.1708007 49.621486 87.1708007 "></polygon>
        </g>
      </svg>              
    </div>
  )

  if (!data.has("fx")) return el;
  return React.createElement(FXReveal, data.get("fx").toJS(), el);

}