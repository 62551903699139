const Dims = (function() {

  function Dims(w, h) {
    this.w = parseInt(w, 10);
    this.h = parseInt(h, 10);
  }

  Dims.fromString = function(str) {
    var s;
    s = str.split("x");
    return new Dims(parseInt(s[0], 10), parseInt(s[1], 10));
  };

  Dims.prototype.isPortrait = function() {
    return this.h > this.w;
  }

  Dims.prototype.isLandscape = function() {
    return this.w >= this.h;
  }

  Dims.prototype.area = function() {
    return this.w * this.h;
  };

  Dims.prototype.ratio = function() {
    return this.w / this.h;
  };  

  Dims.prototype.copy = function() {
    return new Dims(this.w, this.h);
  };

  Dims.prototype.transformH = function(newH) {
    return new Dims(Math.floor(this.w * newH / this.h), newH);
  };

  Dims.prototype.transformW = function(newW) {
    return new Dims(newW, Math.floor(newW * this.h / this.w));
  };

  Dims.prototype.multiply = function(factor) {
    return new Dims(this.w * factor, this.h * factor);
  };

  Dims.prototype.fitsInside = function(dims) {
    return this.w <= dims.w && this.h <= dims.h;
  };

  Dims.prototype.fitsOutside = function(dims) {
    return this.w >= dims.w && this.h >= dims.h;
  };

  Dims.prototype.sameAs = function(dims) {
    return this.w === dims.w && this.h === dims.h;
  };

  Dims.prototype.transformToFit = function(dims) {
    var a;
    if (dims.sameAs(this)) {
      return dims;
    }
    a = this.transformW(dims.w);
    if (!a.fitsInside(dims)) {
      a = a.transformH(dims.h);
    }
    return a;
  };

  Dims.prototype.transformToFitNoScaleUp = function(dims) {
    if (dims.sameAs(this)) {
      return dims;
    }
    if (this.fitsInside(dims)) {
      return this;
    }
    return this.transformToFit(dims);
  };

  Dims.prototype.transformToMin = function(dims) {
    var a;
    if (dims.sameAs(this)) {
      return dims;
    }
    a = this.transformW(dims.w);
    if (!a.fitsOutside(dims)) {
      a = a.transformH(dims.h);
    }
    return a;
  };

  Dims.prototype.toString = function() {
    return this.w + "x" + this.h;
  };

  return Dims;

})();

export default Dims;