import {Map, List} from "immutable";
import {marginStyles, applyStyles, groupStyles} from "../../ModuleStyles";

export default function Line(props) {

  let {data} = props;
  let styles = getStyles(data).toJS();
  return (
    <div className="Line" style={styles} />
  )

}

function getStyles(data) {
  let defaultStyles = Map({
    "display": "inline-block",
    "width": "50%",
    "height": "2px",
    "backgroundColor": "#888"
  });
  
  return applyStyles(
          groupStyles(
            marginStyles(), 
            List(["width", "backgroundColor", "height"])
          ), 
          data.get("styles"), 
          defaultStyles
        );
}