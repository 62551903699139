import React, {Component} from "react";
import ImagePreloader from "../../../lib/ImagePreloader";
import Loading from "../../components/Loading";
import {cx} from "../../../lib/ClassSet";
import {List} from "immutable";

function prepareUrlForLoader(result = List()) {
  return result.map(x => {
    return {id: x, url: x }
  }).toArray();
}

function shouldPreload(current, offset) {
  return (Math.abs(current - offset) <= 1);
}

class Slide extends Component{

  constructor(props) {
    super(props);
    this.handlePreloadComplete = this.handlePreloadComplete.bind(this);
    this.state = {
      loaded: false,
      urlToLoad: ""
    }
  }

  componentDidMount() {
    this.preloader = new ImagePreloader({
      onComplete: this.handlePreloadComplete
    })
    this.preloadImages();
  }

  componentWillUnmount() {
    this.preloader.destroy();
    this.preloader = null;
  }

  reset() {
    this.preloader.reset();
    this.setState({loaded: false, urlToLoad: ""});
    this.preloadImages();
  }

  preloadImages() {
    if (!shouldPreload(this.props.current, this.props.offset)) return;
    let urlToLoad = this.props.getUrl(this.props.image);
    if (this.state.urlToLoad === urlToLoad) return;

    this.setState({loaded: false, urlToLoad: urlToLoad}, () => {
      this.preloader.load(prepareUrlForLoader(List([urlToLoad])));
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // If image has changes, then preload.
    if (this.props.getUrl(prevProps.image) !== this.props.getUrl(this.props.image)) {
      this.reset();
      return;
    }
    // or if the current index or offset has changed.
    if (prevProps.offset !== this.props.offset || prevProps.current !== this.props.current) {
      this.preloadImages();
    }
  }

  handlePreloadComplete() {
    this.setState({loaded: true});
  }

  render() {
    let isLoaded = this.state.loaded;
    // We don't draw what can't be seen.
    // if (Math.abs(this.props.current - this.props.offset) > 1) return null;

    let styles = {
      position: "absolute",
      top: 0,
      left: `${this.props.offset*100}%`,
      width: "100%",
      height: "100%"
    }    
    let bgStyles = {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    }

    let classes = cx({
      "slide": true,
      "s-loaded": isLoaded,
      "s-not-loaded": !isLoaded
    });

    let loader = null;
    if (!isLoaded) {
      loader = <Loading />
    } else {
      bgStyles.backgroundImage = `url(${this.props.getUrl(this.props.image)})`;
      bgStyles.backgroundSize = "cover";
      bgStyles.backgroundPosition = this.props.image.get("position", "50% 50%");
    }

    return (
      <div className={classes} style={styles}>
        <div className="bg" style={bgStyles} />
        {loader}
      </div>
    )
  }

}

export default Slide;