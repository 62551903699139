import {Record, List} from "immutable";

export const META_LOADED = "loaded";
export const META_NOT_LOADED = "not_loaded";
export const META_LOADING = "loading";
export const META_ERROR = "error";

export const Meta = Record({
  status: META_NOT_LOADED,
  didInvalidate: false,
  errorCode: 0,
  errorMessage: ""
})

export const SearchMeta = Record({
  status: META_NOT_LOADED,
  didInvalidate: false,
  errorCode: 0,
  errorMessage: "",
  page: 1,
  result: List()
})
