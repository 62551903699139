import {List} from "immutable";
import {marginStyles, paddingStyles, textStyles, applyStyles, groupStyles, applyAbsoluteMeasurements} from "../../ModuleStyles";
import {cx} from "../../../lib/ClassSet";
import "./Intro.scss";

export default function Intro(props) {

  let {data, moduleId, width, height} = props;
  let styles = getStyles(data, width, height).toJS();
  let innerStyles = getInnerStyles(data).toJS();

  let classes = cx({
    "Intro": true,
    "Intro-xs": data.get("size", "") === "xsmall",
    "Intro-sm": data.get("size", "") === "small",
    "Intro-md": data.get("size", "") === "medium",
    "Intro-lg": data.get("size", "") === "large",
    "Intro-xl": data.get("size", "") === "xlarge"
  });

  if (data.has("theme")) classes = `${classes} Intro-${data.get("theme")}`;

  return (
    <div className={classes} style={styles} id={moduleId}>
      <div className="inner" style={innerStyles}>
        {renderPretitle(data)}
        {renderTitle(data)}
        {renderSubtitle(data)}
      </div>
    </div>
  )
}


function getStyles(data, width, height) {
  return applyAbsoluteMeasurements(
    applyStyles(
      groupStyles(
        marginStyles(), 
        paddingStyles(), 
        List(["@flex", "backgroundColor", "@backgroundImages", "textAlign", "textTransform", "width", "height"])
      ), 
      data.get("styles")
    ),
    width,
    height      
  )
}

function getInnerStyles(data) {
  return applyStyles(
      groupStyles(
        List(["maxWidth"]), 
        marginStyles()
      ), 
      data.getIn(["styles", "#inner"])
    );
}

function renderPretitle(data) {
  if (!data.has("pretitle")) return null;
  let styles =
    applyStyles(
      groupStyles(
        marginStyles(), 
        paddingStyles(), 
        textStyles(),
        List(["color", "backgroundColor", "padding"])
      ), 
      data.getIn(["styles", "#pretitle"])      
    ).toJS();

  return <div key="pretitle" className="pretitle" style={styles}>{data.get("pretitle")}</div>
}

function renderTitle(data) {
  if (!data.has("title")) return null;
  let styles =
    applyStyles(
      groupStyles(
        marginStyles(), 
        textStyles(),
        List(["color"])
      ), 
      data.getIn(["styles", "#title"])
    ).toJS()

  return <div key="title" className="title" style={styles}>{data.get("title")}</div>
}

function renderSubtitle(data) {
  if (!data.has("subtitle")) return null;
  let styles =
    applyStyles(
      groupStyles(
        marginStyles(), 
        paddingStyles(), 
        textStyles(),
        List(["color", "backgroundColor", "padding"])
      ), 
      data.getIn(["styles", "#subtitle"])
    ).toJS()

  return <div key="subtitle" className="subtitle" style={styles}>{data.get("subtitle")}</div>
}