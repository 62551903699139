import {List} from "immutable";
import Dimensions from "../lib/Dimensions";
const TRANS_KEY = "@transformations";

export function transformData(data, w, h) {
  // Do we have any transformations.
  if (data.has(TRANS_KEY)) return startTransformation(data, w, h);
  return data;
}

function startTransformation(data, w, h) {
  let transformations = data.get(TRANS_KEY);
  let original = data.remove(TRANS_KEY);
  return transformMobile(original, transformations, w, h);
}

function transform(org, superset) {
  let merged = org.mergeDeep(superset);
  return List([fixModuleOrder, fixColumnOrder, fixBackgroundImages]).reduce((reduction, f) => {
    return f(reduction, superset);
  }, merged);
}

function fixModuleOrder(merged, superset) {
  if (superset.has("moduleOrder")) {
    return merged.set("moduleOrder", superset.get("moduleOrder"));
  }
  return merged;  
}

function fixColumnOrder(merged, superset) {
  if (superset.has("columnOrder")) {
    return merged.set("columnOrder", superset.get("columnOrder"));
  }
  return merged;
}

function fixBackgroundImages(merged, superset) {
  return merged;
  // TODO: Apply this, but needs update on all sites.
  // if (superset.hasIn(["styles", "@backgroundImages"])) {
  //   return merged.setIn(["styles", "@backgroundImages"], superset.getIn(["styles", "@backgroundImages"]));
  // }
  // return merged;
}

function transformMobile(data, transformations, w, h) {  
  if (transformations.has("mobile-p") && withinPortrait(w, h)) {
    return transform(data, transformations.get("mobile-p"));
  } 
  
  if (transformations.has("mobile-l") && withinLandscape(w, h)) {
    return transform(data, transformations.get("mobile-l"));
  } 

  if (transformations.has("mobile") && withinMobile(w, h)) {
    return transform(data, transformations.get("mobile"));
  }

  if (transformations.has("minDims")) {
    let sourceDims = new Dimensions(w, h);
    let match = transformations.get("minDims").find(v => {
      return sourceDims.fitsOutside(Dimensions.fromString(v.get("@dims")));
    });
    if (match) {
      return transform(data, match.delete("@dims"));
    }
  }

  if (transformations.has("maxDims")) {
    let sourceDims = new Dimensions(w, h);
    let match = transformations.get("maxDims").find(v => {
      return sourceDims.fitsInside(Dimensions.fromString(v.get("@dims")));
    });
    if (match) {
      return transform(data, match.delete("@dims"));
    }
  }

  return data;
}

function withinMobile(w, h) {
  return ((w <= 428 && h <= 926) || (w <= 926 && h<= 428));
}

export function withinPortrait(w, h) {
  return (w <= 428 && h <= 926);
}

export function withinLandscape(w, h) {
  return (w <= 926 && h<= 428);
}
