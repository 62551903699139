import {Map} from "immutable";
import {META_LOADED, META_LOADING} from "./Meta";

// const hasMeta = (listing) => {
//   return (listing || Map()).has("meta");
// }

// const hasError = (listing) => {
//   return hasMetaError(listing.get("meta"));
// }

// const hasMetaError = (meta = Map()) => {
//   return meta.get("status", "") === META_ERROR;
// }

// const getErrorMessage = (listing) => {
//   return getMetaErrorMessage(listing.get("meta"));
// }

// const getMetaErrorMessage = (meta = Map()) => {
//   return meta.get("errorMessage", ""); 
// }

// const isLoading = (listing) => {
//   return isMetaLoading(listing.get("meta"));
// }

export function isMetaLoading(meta = Map()) {
  return meta.get("status", "") === META_LOADING;
}

export function isLoaded(listing) {
  return isMetaLoaded(listing.get("meta"));
}

export function isMetaLoaded(meta = Map()) {
  return meta.get("status", "") === META_LOADED;
}

// const isNotLoaded = (listing) => {
//   return isMetaNotLoaded(listing.get("meta"));
// }

// const isMetaNotLoaded = (meta = Map()) => {
//   return meta.get("status", "") === META_NOT_LOADED;
// }

// const isNotFound = (listing) => {
//   return isMetaNotFound(listing.get("meta"));
// }

// const isMetaNotFound = (meta = Map()) => {
//   return (meta.get("status", "") === META_ERROR) 
//             && (meta.get("error", "") === "not_found");
// }

// const isInvalidated = (listing) => {
//   return isMetaInvalidated(listing.get("meta"));
// }

export function isMetaInvalidated (meta = Map()) {
  return meta.get("didInvalidate", false);
}  