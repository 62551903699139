import fetch from "isomorphic-fetch";
import {parse} from "./StringKeyConverter";
import {BadRequestException, BadRequestExceptionWithError, AuthenticationError, InvalidTokenException, 
  ExpiredTokenException, ExpiredRefreshTokenException, ServerErrorException, UserSuspendedException, 
  NotFoundException, UnprocessableEntity} from "./Exceptions";

export {buildPostPayload, buildAuthPostPayload, 
  buildGetPayload, buildAuthGetPayload,
  buildPutPayload, buildAuthPutPayload} from "./JSONPayload";

export function checkServerErrors(response) {
  return new Promise((resolve, reject) => {
    if (response.status === 400 || response.status === 422) {
      if (/json/.test(response.headers.get("Content-Type"))) {
        return response.text()
          .then(text => parse(text))
          .then(convertedText => JSON.parse(convertedText))
          .then(json => {
            if (response.status === 400) {
              reject(new BadRequestExceptionWithError(json));
            } else {
              reject(new UnprocessableEntity(json));
            }
          });
      } else {
        reject(new BadRequestException());
      }
    } else if (response.status === 401) {
      if (/json/.test(response.headers.get("Content-Type"))) {
        return response.json().then(json => {
          switch(json.error) {
            case "authentication_error":
              reject(new AuthenticationError());
              break;
            case "invalid_token":
              reject(new InvalidTokenException());
              break;
            case "expired_token":
              reject(new ExpiredTokenException());
              break;
            case "expired_refresh_token":
              reject(new ExpiredRefreshTokenException());
              break;
            case "user_suspended":
              reject(new UserSuspendedException());
              break;
            default:
              reject(new AuthenticationError());
          }
        });
      } else {
        reject(new AuthenticationError());
      }
    } else if (response.status === 404) {
      reject(new NotFoundException());
    }

    if (response.status >= 500) {
      reject(new ServerErrorException());
    }

    resolve(response);
  });
}

function transformExceptionToError(e) {
  if (e instanceof BadRequestExceptionWithError || e instanceof UnprocessableEntity) {
    return {
      error: {
        code: e.code,
        name: e.name,
        message: e.message,
        info: e.error
      }
    }
  }

  return {
    error: {
      code: e.code,
      name: e.name,
      message: e.message
    }
  }
}

export function makeRequest(url, payload) {
  return fetch(url, payload)
    .then(checkServerErrors)
    .then(response => {
      return response.text().then(text => ({text, response}));
    })
    .then(({text, response}) => {
      return {text: parse(text), response};
    })
    .then(({text, response}) => {
      return {json: JSON.parse(text), response};
    })
    .then(({json, response}) => {
      if (!response.ok) {
        throw new Error("An error occured");
      }
      return {response: json};
    })
    .catch(e => {
      console.log(`Exception (${url})`, e);
      return transformExceptionToError(e);
    })
}
