import { takeEvery, put } from 'redux-saga/effects'
import {FETCH_PAGE, fetchPageFetching, fetchPageSuccess, fetchPageFailed} from "../actions/pageActions";
import {getPage} from "../api/cmsApi";
import {isMetaLoading, isMetaLoaded, isMetaInvalidated} from "../MetaTools";

function* doFetchPage(baseAction) {
  let pageId = baseAction.pageId;
  const pageMeta = baseAction.meta;
  if (isMetaLoading(pageMeta)) return;
  if (isMetaLoaded(pageMeta) && !isMetaInvalidated(pageMeta)) return;
  yield put(fetchPageFetching(pageId));
  const {response, error} = yield getPage(pageId, false);
  if (error) {
    yield put(fetchPageFailed(pageId, error.errorCode, error.message));
  } else {
    yield put(fetchPageSuccess(pageId, response.entities, response.result));
  }
}

export default function* watch() {
  yield takeEvery(FETCH_PAGE, doFetchPage);
}